export var getDivider = function (maxValue) {
    if (maxValue === undefined) {
        return undefined;
    }
    if (maxValue >= 1000000000) {
        return 1000000;
    }
    if (maxValue >= 1000000) {
        return 1000;
    }
    return 1;
};
export var getDividerLabel = function (divider) {
    return divider === 1000000 ? "M" : divider === 1000 ? "k" : "";
};
export var formatNumber = function (value, digits) {
    return value !== undefined
        ? value === null || value === void 0 ? void 0 : value.toLocaleString("en-US", digits !== undefined ? { maximumFractionDigits: digits } : undefined)
        : undefined;
};
