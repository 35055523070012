var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useFetch from "../../hooks/useFetch";
import { isAdmin, useAuthentication } from "../../util/auth";
import styled from "styled-components";
import { theme } from "../../theme";
import { Link } from "react-router-dom";
// import voyageHero from "../../../images/hero-voyage-calculator.png";
import fleetHero from "../../../images/hero-fleet-calculator.png";
import poolHero from "../../../images/hero-pools.png";
import { useAuth0 } from "@auth0/auth0-react";
import { LoaderStatus } from "../../util/apiUtils";
import { A, P, UISection } from "../common-styled-components";
var ToolGrid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1280px;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));\n  gap: ", ";\n  padding: ", ";\n"], ["\n  width: 100%;\n  max-width: 1280px;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));\n  gap: ", ";\n  padding: ", ";\n"])), theme.spacing(4), theme.spacing(4));
var ToolCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"])), theme.spacing(0));
var ToolImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var StyledLink = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration-style: none;\n  text-decoration-line: none;\n"], ["\n  color: ", ";\n  text-decoration-style: none;\n  text-decoration-line: none;\n"])), theme.colors.blackText);
var ToolHeader = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: 0;\n  text-decoration: none;\n  color: ", ";\n  text-decoration-style: none;\n  text-decoration-line: none;\n"], ["\n  ", ";\n  ", ";\n  margin: 0;\n  text-decoration: none;\n  color: ", ";\n  text-decoration-style: none;\n  text-decoration-line: none;\n"])), theme.fontBold, theme.fontSize(1), theme.colors.blackText);
var tools = [
    {
        name: "Fleet Calculator",
        image: fleetHero,
        description: "",
        path: "/fleet-calculator",
        adminOnly: false,
        public: false,
    },
    {
        name: "Surplus calculator",
        image: fleetHero,
        description: "",
        path: "/surplus-calculator",
        adminOnly: true,
        public: false,
    },
    {
        name: "Compliance Pooling",
        image: poolHero,
        description: "",
        path: "/compliance",
        adminOnly: true,
        public: false,
    },
];
export default (function () {
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, loginWithRedirect = _a.loginWithRedirect;
    var user = useAuthentication().user;
    var languageData = useFetch("calculator/language/en", undefined)[0];
    var texts = languageData.status === LoaderStatus.Success
        ? languageData.data
        : undefined;
    var prod = process.env.NODE_ENV === "production";
    if (!texts) {
        return _jsx("div", {});
    }
    var filteredTools = tools.filter(function (tool) {
        return tool.adminOnly ? isAdmin(user) : tool.public || isAuthenticated;
    });
    if (!filteredTools.length) {
        return (_jsx(UISection, { children: _jsx(P, { children: _jsx(A, __assign({ onClick: function (e) {
                        e.preventDefault();
                        loginWithRedirect();
                    }, style: { cursor: "pointer" } }, { children: "Log in" })) }) }));
    }
    return (_jsx(ToolGrid, { children: filteredTools.map(function (tool) { return (_jsx(StyledLink, __assign({ to: tool.path }, { children: _jsxs(ToolCard, { children: [_jsx(ToolImage, { src: tool.image }), _jsxs(ToolHeader, { children: [!isAuthenticated ? "Public " : "", tool.name] })] }) }), tool.path)); }) }));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
