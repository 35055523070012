var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { theme } from "../../../theme";
var KPIContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0 ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0 ", ";\n"])), theme.spacing(3));
var NumberAndUnit = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n"])));
var Number = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  text-align: center;\n"], ["\n  ", ";\n  ", ";\n  text-align: center;\n"])), theme.fontBold, theme.fontSize(7));
var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin-top: ", ";\n  text-align: center;\n"], ["\n  ", ";\n  ", ";\n  margin-top: ", ";\n  text-align: center;\n"])), theme.fontBold, theme.fontSize(1), theme.spacing(2));
export default (function (_a) {
    var color = _a.color, value = _a.value, title = _a.title;
    return (_jsxs(KPIContainer, { children: [_jsx(NumberAndUnit, { children: _jsx(Number, __assign({ style: { color: color } }, { children: value !== null && value !== void 0 ? value : "N/A" })) }), _jsx(Title, { children: title })] }));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
