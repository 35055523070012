var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { round } from "lodash";
import { theme } from "../../theme";
export var getFuelColor = function (fuel) {
    switch (fuel) {
        case 1:
            return theme.colors.grey(1);
        case 2:
            return theme.colors.grey(2);
        case 8:
            return theme.colors.grey(3);
        case 23:
            return theme.colors.greenScale[0];
        case 24:
            return theme.colors.greenScale[1];
        default:
            return theme.colors.blue;
    }
};
export var scaleEmissions = function (amountT) {
    if (Math.abs(amountT) > 100) {
        return round(amountT, 0);
    }
    if (Math.abs(amountT) > 10) {
        return round(amountT, 1);
    }
    return round(amountT, 2);
};
var getTransactionColor = function (type) {
    switch (type) {
        case "from":
            return theme.colors.darkBlue;
        case "to":
            return theme.colors.green;
        case "neutral":
            return theme.colors.blackText;
    }
};
export var getTransactionPOI = function (transaction, type) {
    return {
        date: new Date(transaction.transaction_date),
        text: "".concat(transaction.quantity, " tCO\u2082e from ").concat(transaction.from, " to ").concat(transaction.to),
        color: getTransactionColor(type),
    };
};
export var combineFuels = function (fuelAmounts) {
    return fuelAmounts.reduce(function (result, f) {
        var _a;
        var _b;
        return __assign(__assign({}, result), (_a = {}, _a[f.fuel] = f.amount + ((_b = result[f.fuel]) !== null && _b !== void 0 ? _b : 0), _a));
    }, {});
};
