var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import useFetch from "../../hooks/useFetch";
import { Button, P, PageContent, SectionTitle, TextContent, TitleWithExtraContent, } from "../common-styled-components";
import ToolLayout from "../custom/ToolLayout";
import { useMetadata } from "../../hooks/useMetadata";
import VoyageSelectionPanel from "./VoyageSelectionPanel";
import EnergySourceSelectionPanel from "../custom/EnergySourceSelectionPanel";
import VoyageResults from "./VoyageResults";
import styled from "styled-components";
import { ToggleableContent } from "../generic/ToggleableContent";
import { isEqual, omit } from "lodash";
import { useMergedCalculatorState } from "../../hooks/useMergedCalculatorState";
import { getInitialComparisonFuelParam, getInitialSecondComparisonFuelParam, } from "../../util/calculatorUtils";
import { LoaderStatus } from "../../util/apiUtils";
import { useAuthentication } from "../../util/auth";
var SubmitButton = function (_a) {
    var onClick = _a.onClick, disabled = _a.disabled;
    return (_jsx(Button, __assign({ onClick: onClick, disabled: disabled }, { children: "Submit" })));
};
var canCreateCalculation = function (params, metadata) {
    var _a;
    if (!params.comparison_fuels) {
        return false;
    }
    var originalIntensity = metadata["intensity-fuel-engine"][params.fuels[0].fuel_type][params.fuels[0].engine_type];
    var compareIntensity = (_a = params.comparison_fuels[0].intensity) !== null && _a !== void 0 ? _a : metadata["intensity-fuel-engine"][params.comparison_fuels[0].fuel_type][params.comparison_fuels[0].engine_type];
    return originalIntensity > compareIntensity;
};
var SubmitContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n"])));
export default (function () {
    var _a;
    var _b = useMergedCalculatorState(), voyageParams = _b[0], setVoyageParams = _b[1], removeComparison = _b[2];
    var _c = useState(), previousParams = _c[0], setPreviousParams = _c[1];
    var metadata = useMetadata()[0];
    var _d = useState(false), hasDoneInitialLoad = _d[0], setHasDoneInitialLoad = _d[1];
    // Init params from metadata
    useEffect(function () {
        if (metadata && voyageParams === undefined) {
            var engine = 1;
            var fuel = 1;
            setVoyageParams({
                emission_allowance_price: 90,
                imo_number: 9590838,
                ship_type: 0,
                draft: 11,
                ballast: false,
                season: 2,
                speed: 12,
                departure_port: "SGSIN",
                departure_port_label: "Singapore (SGSIN)",
                destination_port: "NLRTM",
                destination_port_label: "Rotterdam (NLRTM)",
                disabled_canals: [],
                disabled_passages: [],
                fuels: [
                    {
                        fuel_price: metadata.fuels[fuel].price,
                        engine_type: engine,
                        engine_efficiency: metadata["efficiency-fuel-engine"][fuel][engine],
                        fuel_type: fuel,
                    },
                ],
                comparison_fuels: [getInitialComparisonFuelParam(metadata)],
            });
        }
    }, [metadata]);
    var getAuthHeader = useAuthentication().getAuthHeader;
    var _e = useFetch("calculator/voyage", undefined, true), voyageCalculation = _e[0], _setUrl = _e[1], setBody = _e[2];
    var canCalculate = useDeepMemo(function () {
        return metadata && voyageParams
            ? [canCreateCalculation(voyageParams, metadata)]
            : [false];
    }, [voyageParams, metadata])[0];
    useEffect(function () {
        if (canCalculate &&
            !hasDoneInitialLoad &&
            !isEqual(previousParams, voyageParams)) {
            setHasDoneInitialLoad(true);
            setBody(JSON.stringify(voyageParams));
            setPreviousParams(voyageParams);
        }
    }, [voyageParams, previousParams, canCalculate]);
    var onSubmit = function (e) {
        e.preventDefault();
        if (canCalculate && !isEqual(previousParams, voyageParams)) {
            setPreviousParams(voyageParams);
            setBody(JSON.stringify(voyageParams));
        }
    };
    // Data logging
    // console.info("params", calculatorParams);
    // console.info(
    //   voyageCalculation.status === LoaderStatus.Success
    //     ? "Results: " + JSON.stringify(voyageCalculation.data, undefined, 2)
    //     : "loading"
    // );
    // console.info(
    //   metadata.status === LoaderStatus.Success
    //     ? "Metadata: " + JSON.stringify(metadata.data, undefined, 2)
    //     : "loading"
    // );
    var languageData = useFetch("calculator/language/en", undefined)[0];
    var texts = languageData.status === LoaderStatus.Success
        ? languageData.data
        : undefined;
    var submitIsDisabled = isEqual(previousParams, voyageParams) || !canCalculate;
    var selections = metadata && voyageParams && texts ? (_jsxs(_Fragment, { children: [_jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(SubmitButton, { onClick: onSubmit, disabled: submitIsDisabled }) }, { children: "Voyage options" })), _jsx(VoyageSelectionPanel, { title: "", initialParams: omit(voyageParams, "comparison_fuels", "fuels"), metadata: metadata, setParams: setVoyageParams, texts: texts }), _jsx(SectionTitle, { children: "Energy source options" }), _jsx(EnergySourceSelectionPanel, { title: "Fossil fuel", isComparison: false, useFOC: false, initialParams: voyageParams.fuels[0], metadata: metadata, setParams: setVoyageParams, fuelIndex: 0, texts: texts }), _jsx(EnergySourceSelectionPanel, { title: "Low-carbon energy source", isComparison: true, useFOC: false, initialParams: voyageParams.comparison_fuels[0], metadata: metadata, setParams: setVoyageParams, fuelIndex: 0, texts: texts }), _jsx(ToggleableContent, __assign({ buttonTitle: "Add", closeText: "Remove", onClose: function () { return removeComparison(true, 1); }, onOpen: function () {
                    return setVoyageParams({
                        comparison_fuels: [
                            voyageParams.comparison_fuels[0],
                            getInitialSecondComparisonFuelParam(metadata),
                        ],
                    });
                } }, { children: _jsx(EnergySourceSelectionPanel, { title: "Second low-carbon energy source", isComparison: true, useFOC: false, initialParams: voyageParams.comparison_fuels[1], metadata: metadata, setParams: setVoyageParams, fuelIndex: 1, texts: texts }) })), _jsx(SubmitContainer, { children: _jsx(SubmitButton, { onClick: onSubmit, disabled: submitIsDisabled }) })] })) : (texts && _jsx(P, { children: texts.loading_options }));
    var results = !canCalculate && texts ? (_jsx(P, __assign({ style: { height: "600px" } }, { children: "Select a comparison energy source with a lower intensity than the fossil energy source." }))) : voyageParams &&
        previousParams &&
        (voyageCalculation === null || voyageCalculation === void 0 ? void 0 : voyageCalculation.status) === LoaderStatus.Success &&
        ((_a = voyageCalculation.data) === null || _a === void 0 ? void 0 : _a.comparisons) &&
        voyageCalculation.data.comparisons.length > 0 &&
        metadata &&
        texts ? (_jsx(VoyageResults, { params: previousParams, fuelTypes: metadata.fuels, calculation: voyageCalculation.data, metadata: metadata, texts: texts })) : (voyageCalculation === null || voyageCalculation === void 0 ? void 0 : voyageCalculation.status) === LoaderStatus.Idle ? (_jsx(TextContent, { children: _jsx(P, __assign({ style: { height: "600px" } }, { children: "Submit new voyage options to create a calculation." })) })) : (texts && (_jsx(TextContent, { children: _jsx(P, __assign({ style: { height: "600px" } }, { children: "Loading results\u2026 Please allow for up to 30 seconds of waiting time." })) })));
    return texts ? (_jsx(PageContent, { children: _jsx(ToolLayout, { name: "Voyage calculator", intro: "", selections: selections, results: results }) })) : (_jsx(PageContent, {}));
});
var templateObject_1;
