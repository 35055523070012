import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Selector, SelectorLabel } from "../../../common-styled-components";
import { TextInput } from "../../../generic/TextInput";
import useFetch from "../../../../hooks/useFetch";
import { LoaderStatus } from "../../../../util/apiUtils";
import Select from "react-select";
var emptyOption = { label: "Select company", value: null };
export var UserEditor = function (_a) {
    var _b, _c, _d;
    var state = _a.state, updateValue = _a.updateValue, modalType = _a.modalType;
    var overview = useFetch("admin/overview.json", undefined)[0];
    if (modalType === "remove") {
        return _jsxs("div", { children: ["Remove ", state.username, "?"] });
    }
    var companyOptions = [emptyOption].concat(overview.status === LoaderStatus.Success
        ? overview.data.companies.map(function (c) { return ({
            label: c.name,
            value: c.id,
        }); })
        : []);
    return (_jsxs(_Fragment, { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Name" }), _jsx(TextInput, { value: (_b = state.name) !== null && _b !== void 0 ? _b : "", onChange: function (newValue) { return updateValue("name", newValue); } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Email" }), _jsx(TextInput, { value: (_c = state.username) !== null && _c !== void 0 ? _c : "", onChange: function (newValue) { return updateValue("username", newValue); } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Company" }), _jsx(Select, { options: companyOptions, onChange: function (newValue) { return updateValue("company_id", newValue === null || newValue === void 0 ? void 0 : newValue.value); }, value: (_d = companyOptions.find(function (c) { return c.value === state.company_id; })) !== null && _d !== void 0 ? _d : emptyOption, isOptionSelected: function (option) { return option.value === state.company_id; } })] })] }));
};
