var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useFetch from "../../../hooks/useFetch";
import { BackLink, Bold, PageContent, PTight, Row, SectionTitle, SeparatedRow, ToolTitle, } from "../../common-styled-components";
import { log } from "../../../util/log";
import { LoaderStatus } from "../../../util/apiUtils";
import { theme } from "../../../theme";
import { PoolTable } from "../common/tables/PoolTable";
import { useMetadata } from "../../../hooks/useMetadata";
import { FleetTable } from "../common/tables/FleetTable";
import { ShipTable } from "../common/tables/ShipTable";
import NumberKPI from "../common/NumberKPI";
import { routeNames } from "../../../util/routes";
import { defaultYear } from "../../../util/constants";
import { Footer } from "../../page-components/Footer";
import DownloadPDFButton from "../common/DownloadPDFButton";
export default (function () {
    var user = useFetch("user/data.json")[0];
    var pools = useFetch("pool/pools.json", JSON.stringify({ pool_id: null, year: defaultYear }))[0];
    var fleets = useFetch("fleet/fleets.json", JSON.stringify({ year: defaultYear }))[0];
    var ships = useFetch("ship/ships.json", JSON.stringify({ year: defaultYear }))[0];
    var metadata = useMetadata()[0];
    log([
        {
            label: "User",
            content: user.status === LoaderStatus.Success ? user.data : "loading",
        },
        {
            label: "Pools",
            content: pools.status === LoaderStatus.Success ? pools.data : "loading",
        },
        {
            label: "Fleets",
            content: fleets.status === LoaderStatus.Success ? fleets.data : "loading",
        },
        {
            label: "Ships",
            content: ships.status === LoaderStatus.Success ? ships.data : "loading",
        },
    ]);
    if (user.status !== LoaderStatus.Success ||
        !user.data ||
        fleets.status !== LoaderStatus.Success ||
        !fleets.data ||
        pools.status !== LoaderStatus.Success ||
        !pools.data ||
        ships.status !== LoaderStatus.Success ||
        !ships.data ||
        !metadata) {
        return _jsx(PageContent, __assign({ align: "left" }, { children: "Loading\u2026" }));
    }
    var nonCompliantShips = ships.data.filter(function (s) { return s.intensity.adjusted_emission_balance_ton < 0; }).length;
    var nonCompliantPools = pools.data.filter(function (p) { return p.intensity.adjusted_emission_balance_ton < 0; }).length;
    return (_jsxs("div", __assign({ style: { width: "100%", padding: theme.spacing(5) } }, { children: [_jsxs(PageContent, __assign({ align: "left", style: { gap: theme.spacing(5) } }, { children: [_jsx(BackLink, __assign({ to: routeNames.main }, { children: "\u2039 All tools" })), _jsx(ToolTitle, { children: "Compliance pools" }), _jsx(SectionTitle, { children: user.data.company.name }), _jsxs(SeparatedRow, { children: [_jsxs("div", __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsxs(PTight, { children: [_jsx(Bold, { children: "Ships: " }), ships.data.length] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "Pools: " }), pools.data.length] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "Fleets: " }), fleets.data.length] })] })), _jsxs(Row, { children: [_jsx(NumberKPI, { title: "non-compliant ships", value: nonCompliantShips, color: nonCompliantShips > 0
                                            ? theme.colors.blackText
                                            : theme.colors.green }), _jsx(NumberKPI, { title: "non-compliant pools", value: nonCompliantPools, color: nonCompliantPools > 0
                                            ? theme.colors.blackText
                                            : theme.colors.green })] })] }), _jsx(PoolTable, { pools: pools.data, isInAdminTools: false, metadata: metadata }), _jsx(FleetTable, { fleets: fleets.data, isInAdminTools: false, metadata: metadata }), _jsx(ShipTable, { ships: ships.data, isInAdminTools: false, metadata: metadata, complianceYear: defaultYear, userCompanyId: user.data.company.id })] })), _jsx(Footer, { children: _jsx(DownloadPDFButton, { label: "Service description (PDF)", fetchUrl: "documentation/ahti_service_description.pdf", fileName: "ahti_service_description.pdf" }) })] })));
});
