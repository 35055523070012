var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { theme } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";
import { PageContent } from "./components/common-styled-components";
import { Header } from "./components/page-components/Header";
import { Auth0ProviderWithNavigate } from "./components/Auth0ProviderWithNavigate";
var AppMain = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  flex-wrap: nowrap;\n  ", ";\n  background-color: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  flex-wrap: nowrap;\n  ", ";\n  background-color: ", ";\n"])), theme.fontNormal, theme.colors.white);
var LoginInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), theme.spacing(5));
var AuthWrapper = function (_a) {
    var children = _a.children;
    var isLoading = useAuth0().isLoading;
    if (isLoading) {
        return (_jsx(AppMain, { children: _jsx(LoginInfo, { children: "Loading app\u2026" }) }));
    }
    return _jsx("div", { children: children });
};
export default function App() {
    return (_jsx(Auth0ProviderWithNavigate, { children: _jsx(AuthWrapper, { children: _jsxs(PageContent, { children: [_jsx(Header, {}), _jsx(Outlet, {})] }) }) }));
}
var templateObject_1, templateObject_2;
