var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { theme } from "../../../theme";
import { complianceOptions } from "../../../util/compliance/compliance";
import { formatNumber } from "../../../util/amountUtils";
var ComplianceKPIContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0 ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0 ", ";\n"])), theme.spacing(3));
var ComplianceNumberAndUnit = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n"])));
var ComplianceNumber = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  text-align: center;\n"], ["\n  ", ";\n  ", ";\n  text-align: center;\n"])), theme.fontBold, theme.fontSize(7));
var Unit = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  align-self: flex-end;\n  margin-top: -16px;\n  text-align: right;\n"], ["\n  ", ";\n  ", ";\n  align-self: flex-end;\n  margin-top: -16px;\n  text-align: right;\n"])), theme.fontBold, theme.fontSize(0));
var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin-top: ", ";\n  text-align: center;\n"], ["\n  ", ";\n  ", ";\n  margin-top: ", ";\n  text-align: center;\n"])), theme.fontBold, theme.fontSize(1), theme.spacing(2));
var ComplianceText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin-top: ", ";\n  text-align: center;\n"], ["\n  ", ";\n  ", ";\n  margin-top: ", ";\n  text-align: center;\n"])), theme.fontBold, theme.fontSize(0), theme.spacing(0));
var ComplianceIcon = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  margin-right: ", ";\n"], ["\n  width: 24px;\n  height: 24px;\n  margin-right: ", ";\n"])), theme.spacing(1));
export default (function (_a) {
    var _b;
    var value = _a.value, unit = _a.unit, title = _a.title, compliance = _a.compliance;
    var complianceOption = complianceOptions[compliance];
    return (_jsxs(ComplianceKPIContainer, { children: [_jsxs(ComplianceNumberAndUnit, { children: [_jsx(ComplianceNumber, __assign({ style: { color: complianceOption.color } }, { children: (_b = formatNumber(value, 2)) !== null && _b !== void 0 ? _b : "N/A" })), _jsx(Unit, { children: unit })] }), _jsx(Title, { children: title }), _jsxs(ComplianceText, __assign({ style: { color: complianceOption.color } }, { children: [_jsx(ComplianceIcon, { src: complianceOption.icon }), complianceOption.text] }))] }));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
