var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { useCallback, useEffect, useState } from "react";
import { SecondaryButton, SeparatedRow, SmallSubtitle, } from "../../../common-styled-components";
import { TextInput } from "../../../generic/TextInput";
import { EngineEditor } from "./EngineEditor";
import { isEqual } from "lodash";
var List = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n  margin-top: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n  margin-top: ", ";\n"])), theme.spacing(0), theme.spacing(3));
export var ListEditor = function (_a) {
    var title = _a.title, _b = _a.initialValues, initialValues = _b === void 0 ? [] : _b, addDefault = _a.addDefault, editorType = _a.editorType, metadata = _a.metadata, onChange = _a.onChange;
    var _c = useState(initialValues), values = _c[0], setValues = _c[1];
    useEffect(function () {
        if (!isEqual(values, initialValues)) {
            onChange(values);
        }
    }, [values, onChange]);
    var editValue = useCallback(function (newValue, index) {
        setValues(function (prevValues) {
            var updatedValues = __spreadArray([], prevValues, true);
            updatedValues[index] = newValue;
            return updatedValues;
        });
    }, []);
    var remove = useCallback(function (index) {
        setValues(function (prevValues) {
            var updatedValues = prevValues.filter(function (_v, i) { return i !== index; });
            return updatedValues;
        });
    }, [values]);
    return (_jsxs(List, { children: [_jsx(SmallSubtitle, { children: title }), values.map(function (v, index) {
                if (editorType === "string" && typeof v === "string") {
                    return (_jsxs(SeparatedRow, { children: [_jsx(TextInput, { value: v, onChange: function (newValue) { return editValue(newValue, index); } }, index), _jsx(SecondaryButton, __assign({ onClick: function () { return remove(index); } }, { children: "-" }))] }));
                }
                else if (editorType === "ShipEngineType" && typeof v === "object") {
                    return (_jsx(EngineEditor, { metadata: metadata, index: index, updateEngine: function (i, engine) { return editValue(engine, i); }, initialValue: v, remove: remove }, index));
                }
                return null;
            }), _jsx(SecondaryButton, __assign({ onClick: function () { return setValues(__spreadArray(__spreadArray([], values, true), [addDefault], false)); } }, { children: "+ Add" }))] }));
};
var templateObject_1;
