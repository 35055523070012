import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TD, TH, TR } from "../../common-styled-components";
import { sortBy } from "lodash";
var voyageTableColumns = function () { return [
    // {
    //   key: "index",
    //   label: "Usage",
    // },
    {
        key: "fuel",
        label: "Fuel",
    },
    {
        key: "engine",
        label: "Engine",
    },
    {
        key: "amount",
        label: "Amount (t)",
    },
    {
        key: "price",
        label: "Price",
    },
    {
        key: "is_half_rate",
        label: "Half rate",
    },
]; };
var CellContent = function (_a) {
    var index = _a.index, usage = _a.usage, contentKey = _a.contentKey, isInAdminTools = _a.isInAdminTools, metadata = _a.metadata;
    switch (contentKey) {
        case "index":
            return _jsx("div", { children: index });
        case "ship":
            return _jsx("div", { children: usage.ship.name });
        case "fuel":
            return _jsx("div", { children: metadata.fuels[usage.fuel].name });
        case "engine":
            return _jsx("div", { children: metadata.engines[usage.engine.type].engine.name });
        case "is_half_rate":
            return _jsx("div", { children: usage.is_half_rate ? "Y" : "N" });
        default:
            return _jsx("div", { children: usage[contentKey].toString() });
    }
};
export var UsageTable = function (_a) {
    var usages = _a.usages, metadata = _a.metadata, isInAdminTools = _a.isInAdminTools;
    return (metadata && (_jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: voyageTableColumns().map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: sortBy(usages, function (v) { return -v.amount; }).map(function (usage, index) {
                    return (_jsx(TR, { children: voyageTableColumns().map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { index: index + 1, contentKey: c.key, usage: usage, isInAdminTools: isInAdminTools, metadata: metadata }) }, c.key)); }) }, usage.id));
                }) })] })));
};
