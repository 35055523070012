var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { theme } from "../../../theme";
import { Bold, P, SmallSubtitle } from "../../common-styled-components";
import { formatNumber } from "../../../util/amountUtils";
import { Fragment } from "react";
import InfoButton from "../../generic/InfoButton";
import { defaultYear } from "../../../util/constants";
var Box = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  display: grid;\n  grid-template-columns: 140px 1fr 16px;\n  grid-column-gap: ", ";\n  grid-row-gap: ", ";\n  max-width: 360px;\n  align-items: flex-start;\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  display: grid;\n  grid-template-columns: 140px 1fr 16px;\n  grid-column-gap: ", ";\n  grid-row-gap: ", ";\n  max-width: 360px;\n  align-items: flex-start;\n"])), theme.colors.grey(4), theme.spacing(3), theme.spacing(2), theme.spacing(0));
var CalculationDetail = styled(P)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 2px;\n  ", ";\n"], ["\n  margin-bottom: 2px;\n  ", ";\n"])), theme.fontSize(-1));
var processCalculation = function (calculation) {
    var _a, _b;
    var complianceGap = (_a = calculation.co2_surplus.find(function (p) { return p.year === defaultYear; })) === null || _a === void 0 ? void 0 : _a.value;
    var comparisons = calculation.comparisons.map(function (c) {
        var mixYear = c.mixes.find(function (m) { return m.year === defaultYear; });
        var originalPrice = calculation.original_fuel.fuel_cost;
        var mixCost = mixYear
            ? mixYear.original_fuel.fuel_cost + mixYear.alternative_fuel.fuel_cost
            : undefined;
        var costPremium = originalPrice !== undefined && mixCost !== undefined
            ? mixCost - originalPrice
            : undefined;
        return {
            alternativeFuel: c.alternative_fuel.fuel,
            alternativeFuelName: c.alternative_fuel.fuel_name,
            alternativeFuelPrice: mixYear === null || mixYear === void 0 ? void 0 : mixYear.alternative_fuel.fuel_cost_per_ton,
            alternativeFuelAmount: mixYear === null || mixYear === void 0 ? void 0 : mixYear.alternative_fuel.foc,
            alternativeFuelTotal: mixYear === null || mixYear === void 0 ? void 0 : mixYear.alternative_fuel.fuel_cost,
            alternativeFuelPremium: costPremium,
            alternativeFuelShare: mixYear === null || mixYear === void 0 ? void 0 : mixYear.alternative_fuel.energy_percentage,
            originalFuelCost: mixYear === null || mixYear === void 0 ? void 0 : mixYear.original_fuel.fuel_cost_per_ton,
            originalFuelAmount: mixYear === null || mixYear === void 0 ? void 0 : mixYear.original_fuel.foc,
            fuelPlanCost: mixYear === null || mixYear === void 0 ? void 0 : mixYear.total_annual_fuel_costs,
        };
    });
    return {
        penalty: (_b = calculation.penalty.find(function (p) { return p.year === defaultYear; })) === null || _b === void 0 ? void 0 : _b.value,
        complianceGap: complianceGap,
        complianceTransferCost: (complianceGap !== null && complianceGap !== void 0 ? complianceGap : 0) * 300,
        comparisons: comparisons,
    };
};
export var AltCalculations = function (_a) {
    var _b, _c, _d;
    var calculation = _a.calculation, analysis = _a.analysis, metadata = _a.metadata;
    var fuelCalculation = processCalculation(calculation);
    var eoyGap = analysis.ship.forecast.total.adjusted_emission_balance_ton;
    var penaltyPrice = (_d = (_c = (_b = calculation.original_fuel.timeline
        .find(function (d) { return d.year === defaultYear; })) === null || _b === void 0 ? void 0 : _b.cost_per_exceeded_emission_ton.find(function (d) { return d.with_emissions === false; })) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0;
    var penalty = eoyGap < 0 ? -1 * eoyGap * penaltyPrice : 0;
    var COMPLIANCE_PRICE_USD = 240;
    var complianceEstimate = eoyGap < 0 ? -1 * eoyGap * COMPLIANCE_PRICE_USD : 0;
    return (_jsxs("div", { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: 2 } }, { children: "Compliance options" })), _jsxs(Box, { children: [_jsx(CalculationDetail, { children: "Projected compliance gap: " }), _jsxs(CalculationDetail, { children: [_jsx(Bold, { children: formatNumber(eoyGap, 1) }), " tCO\u2082e"] }), _jsx(InfoButton, { children: "Given current mix of fuel use for the rest of the year, this is the projected compliance gap at end-of-year." }), _jsx(CalculationDetail, { children: "Penalty value:" }), _jsxs(CalculationDetail, { children: [_jsx(Bold, { children: formatNumber(penalty, 0) }), " \u20AC"] }), _jsx("div", {}), _jsxs(CalculationDetail, { children: ["Equivalent compliance unit price:", " "] }), _jsxs(CalculationDetail, { children: [_jsx(Bold, { children: formatNumber(complianceEstimate, 0) }), " USD"] }), _jsxs(InfoButton, { children: ["An estimate of how much it would cost to offset the balance by buying compliance units from within the pool at ", COMPLIANCE_PRICE_USD, " ", "$/tCO\u2082e."] }), fuelCalculation.comparisons.map(function (f) {
                        var _a;
                        return (_jsxs(Fragment, { children: [_jsxs(CalculationDetail, { children: ["Cost premium with ", f.alternativeFuelName, ":", " "] }), _jsx(CalculationDetail, { children: f.alternativeFuelPremium !== undefined ? (_jsxs(Bold, { children: [formatNumber(f.alternativeFuelPremium, 0), " \u20AC"] })) : ("") }), _jsxs(InfoButton, { children: ["An estimate of how much compliancy would cost by operating partly with ", f.alternativeFuelName, " for the rest of the year. This would require ", (_a = f.alternativeFuelShare) === null || _a === void 0 ? void 0 : _a.toFixed(), " % energy share from the alternative fuel."] })] }, f.alternativeFuelName));
                    })] })] }));
};
var templateObject_1, templateObject_2;
