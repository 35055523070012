import { linearRegression, linearRegressionLine } from "simple-statistics";
import { subMonths } from "date-fns";
import { chain, minBy } from "lodash";
var getDataWithCutoffDate = function (data, newestMonths) {
    if (newestMonths === undefined || !data.length) {
        return data;
    }
    var dates = data.map(function (point) { return point.date; });
    var lastDate = dates[1];
    var dateCutoff = subMonths(lastDate, newestMonths);
    var filteredData = data.filter(function (d) { return d.date > dateCutoff; });
    if (filteredData.length < 3) {
        // Ensure there is enough to work with
        return data;
    }
    return filteredData;
};
export var predictEOY = function (data, newestMonths) {
    if (!data.length) {
        return undefined;
    }
    var dataWithCutoff = getDataWithCutoffDate(data, newestMonths);
    if (!dataWithCutoff.length) {
        return undefined;
    }
    var firstDateMS = dataWithCutoff[0].date.getTime();
    var regressionValues = dataWithCutoff
        .map(function (point) { return [point.date.getTime() - firstDateMS, point.value]; })
        .filter(function (d) { return d[1] !== undefined; });
    var regression = linearRegression(regressionValues);
    var regressionLine = linearRegressionLine(regression);
    var predictionYear = dataWithCutoff[dataWithCutoff.length - 1].date.getFullYear();
    var predictionEOYDate = new Date(predictionYear, 11, 31);
    var prediction = regressionLine(predictionEOYDate.getTime() - firstDateMS);
    return {
        regression: regression,
        regressionLine: regressionLine,
        predictionEOY: { date: predictionEOYDate, value: prediction },
    };
};
export var getClosestDate = function (dates, targetDate) {
    return minBy(dates, function (date) { return Math.abs(date.getTime() - targetDate.getTime()); });
};
export var getClosestDateValue = function (values, targetDate) {
    var closest = minBy(values, function (dv) {
        return Math.abs(dv.date.getTime() - targetDate.getTime());
    });
    return closest;
};
export var getLatestDateValue = function (values, targetDate) {
    var closest = chain(values)
        .filter(function (dv) { return dv.date.getTime() - targetDate.getTime() <= 0; })
        .maxBy(function (dv) { return dv.date.getTime(); })
        .valueOf();
    return closest;
};
