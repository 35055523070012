var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isValidTransactionInput, } from "../compliance-pool-types";
import { Row, SecondaryButton, SmallButton, Table, TD, TH, TR, } from "../../common-styled-components";
import { Modal } from "./Modal";
import { useState } from "react";
import { theme } from "../../../theme";
import useFetch from "../../../hooks/useFetch";
import { getAuthHeader } from "../../../util/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { LoaderStatus } from "../../../util/apiUtils";
import useEdit from "../../../hooks/useEdit";
import { dateToDateTime } from "../../generic/DateTimeInput";
import { formatNumber } from "../../../util/amountUtils";
var tableColumns = [
    {
        key: "transaction_date",
        label: "Date",
    },
    {
        key: "from",
        label: "From",
    },
    {
        key: "to",
        label: "To",
    },
    {
        key: "quantity",
        label: "Quantity (tCO₂e)",
    },
    {
        key: "production_cost",
        label: "Production cost (USD)",
    },
    {
        key: "transaction_cost",
        label: "Transaction cost (USD)",
    },
    {
        key: "alternative_cost",
        label: "Alt cost (USD)",
    },
    // {
    //   key: "total_to_be_paid",
    //   label: "To be paid (USD)",
    // },
    // {
    //   key: "total_to_be_received",
    //   label: "To be received (USD)",
    // },
    {
        key: "tools",
        label: "Tools",
    },
];
var CellContent = function (_a) {
    var index = _a.index, transaction = _a.transaction, contentKey = _a.contentKey, editTransaction = _a.editTransaction, removeTransaction = _a.removeTransaction;
    switch (contentKey) {
        case "from":
            return _jsx("div", { children: transaction.from.name });
        case "to":
            return _jsx("div", { children: transaction.to.name });
        case "total_to_be_paid":
        case "total_to_be_received":
        case "quantity":
        case "alternative_cost":
        case "production_cost":
        case "transaction_cost":
            return _jsx("div", { children: formatNumber(transaction[contentKey]) });
        case "tools":
            return (_jsxs(Row, { children: [_jsx(SmallButton, __assign({ onClick: function () { return removeTransaction(transaction); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editTransaction(transaction); } }, { children: "Edit" }))] }));
        default:
            return _jsx("div", { children: transaction[contentKey] });
    }
};
export var TransactionTable = function (_a) {
    var transactions = _a.transactions, isInAdminTools = _a.isInAdminTools, poolId = _a.poolId, refresh = _a.refresh;
    var _b = useState({}), modal = _b[0], setModal = _b[1];
    var user = useAuth0().user;
    var pools = useFetch("admin/pools.json", JSON.stringify({ pool_id: poolId, year: 2024 }), __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    var ships = useFetch("admin/ships.json", JSON.stringify({}), __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    var toggleTransactionModal = function () {
        if (modal.type) {
            setModal({});
        }
        else {
            setModal({
                type: "add",
                value: {
                    transaction_date: dateToDateTime(new Date()),
                    year: new Date().getFullYear(),
                },
            });
        }
    };
    var _c = useEdit("admin/transaction.json", __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }), refresh), editStatus = _c[0], executeEdit = _c[1];
    var editTransaction = function (transaction) {
        setModal({
            type: "edit",
            value: __assign(__assign({}, transaction), { from: transaction.from.id, to: transaction.to.id }),
        });
    };
    var removeTransaction = function (transaction) {
        setModal({
            type: "remove",
            value: __assign(__assign({}, transaction), { from: transaction.from.id, to: transaction.to.id }),
        });
    };
    var submit = function (type, value) {
        setModal({});
        if (type === "add") {
            if (isValidTransactionInput(value)) {
                var data = __assign(__assign({}, value), { id: null });
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "edit") {
            if (isValidTransactionInput(value)) {
                executeEdit(value, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    return (_jsxs("div", { children: [modal.type &&
                ships.status === LoaderStatus.Success &&
                pools.status === LoaderStatus.Success && (_jsx(Modal, { type: "transaction", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit, options: {
                    ship: ships.data.map(function (s) { return ({
                        value: s.id,
                        label: s.name,
                    }); }),
                    pool: pools.data.map(function (p) { return ({
                        value: p.id,
                        label: p.name,
                    }); }),
                } })), _jsx(SecondaryButton, __assign({ color: theme.colors.blue, onClick: function () { return toggleTransactionModal(); } }, { children: "+ Add transaction" })), _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: tableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: transactions.map(function (transaction, index) {
                            return (_jsx(TR, { children: tableColumns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { index: index + 1, contentKey: c.key, transaction: transaction, isInAdminTools: isInAdminTools, editTransaction: editTransaction, removeTransaction: removeTransaction }) }, c.key)); }) }, transaction.id));
                        }) })] })] }));
};
