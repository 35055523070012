import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Selector, SelectorLabel } from "../../../common-styled-components";
import useFetch from "../../../../hooks/useFetch";
import { LoaderStatus } from "../../../../util/apiUtils";
import Select from "react-select";
import { NumberInput } from "../../../generic/NumberInput";
var emptyOption = { label: "Select ship", value: null };
export var BalancePlanEditor = function (_a) {
    var _b, _c, _d;
    var state = _a.state, updateValue = _a.updateValue, modalType = _a.modalType;
    var overview = useFetch("admin/overview.json", undefined)[0];
    if (modalType === "remove") {
        return _jsx("div", { children: "Remove balance plan?" });
    }
    var shipOptions = [emptyOption].concat(overview.status === LoaderStatus.Success
        ? overview.data.ships.map(function (c) { return ({
            label: c.name,
            value: c.id,
        }); })
        : []);
    return (_jsxs(_Fragment, { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Ship" }), _jsx(Select, { options: shipOptions, onChange: function (newValue) { return updateValue("ship_id", newValue === null || newValue === void 0 ? void 0 : newValue.value); }, value: (_b = shipOptions.find(function (c) { return c.value === state.ship_id; })) !== null && _b !== void 0 ? _b : emptyOption, isOptionSelected: function (option) { return option.value === state.ship_id; } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Year" }), _jsx(NumberInput, { value: (_c = state.year) !== null && _c !== void 0 ? _c : "", onChange: function (newValue) { return updateValue("year", newValue); } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Planned balance" }), _jsx(NumberInput, { value: (_d = state.balance) !== null && _d !== void 0 ? _d : "", onChange: function (newValue) { return updateValue("balance", newValue); } })] })] }));
};
