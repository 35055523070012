var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isValidCompanyYearlyDataInput, } from "../../compliance-pool-types";
import { SecondaryButton, SmallButton, Table, TD, TH, TR, } from "../../../common-styled-components";
import { useState } from "react";
import { Modal } from "../../common/Modal";
import useEdit from "../../../../hooks/useEdit";
import { theme } from "../../../../theme";
import { sortBy } from "lodash";
var companyYearlyDataTableColumns = function (isInAdminTools) {
    return [
        {
            key: "year",
            label: "Year",
        },
        {
            key: "compliance_selling_price",
            label: "Selling price",
        },
        {
            key: "compliance_buying_price",
            label: "Buying price",
        },
        {
            key: "tools",
            label: "",
        },
    ].filter(function (c) { return c.key !== "tools" || isInAdminTools === true; });
};
var CellContent = function (_a) {
    var companyYearlyData = _a.companyYearlyData, contentKey = _a.contentKey, isInAdminTools = _a.isInAdminTools, removeCompanyYearlyData = _a.removeCompanyYearlyData, editCompanyYearlyData = _a.editCompanyYearlyData;
    switch (contentKey) {
        case "tools":
            return isInAdminTools &&
                removeCompanyYearlyData &&
                editCompanyYearlyData ? (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removeCompanyYearlyData(companyYearlyData); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editCompanyYearlyData(companyYearlyData); } }, { children: "Edit" }))] })) : (_jsx("div", {}));
        default:
            return _jsx("div", { children: companyYearlyData[contentKey] });
    }
};
export var CompanyYearlyDataTable = function (_a) {
    var company = _a.company, metadata = _a.metadata, isInAdminTools = _a.isInAdminTools, refresh = _a.refresh;
    var _b = useState({}), modal = _b[0], setModal = _b[1];
    var _c = useEdit("admin/company_yearly_data.json", refresh), editStatus = _c[0], executeEdit = _c[1];
    var addCompanyYearlyData = function () {
        setModal({
            type: "add",
            value: {
                company_id: company.id,
            },
        });
    };
    var editCompanyYearlyData = function (companyYearlyData) {
        setModal({
            type: "edit",
            value: companyYearlyData,
        });
    };
    var removeCompanyYearlyData = function (companyYearlyData) {
        setModal({
            type: "remove",
            value: companyYearlyData,
        });
    };
    var submit = function (type, value) {
        setModal({});
        if (type === "add") {
            if (isValidCompanyYearlyDataInput(value)) {
                var data = __assign({ id: null }, value);
                executeEdit(data, "PUT");
            }
            else {
                alert("CompanyYearlyData not valid");
            }
        }
        if (type === "edit") {
            if (isValidCompanyYearlyDataInput(value)) {
                var data = __assign({ id: value.id }, value);
                executeEdit(data, "PUT");
            }
            else {
                alert("CompanyYearlyData not valid");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    var companyYearlyData = company.years;
    var columns = companyYearlyDataTableColumns(isInAdminTools);
    return (metadata && (_jsxs("div", { children: [modal.type && (_jsx(Modal, { type: "companyYearlyData", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit })), isInAdminTools && (_jsx(SecondaryButton, __assign({ onClick: addCompanyYearlyData, color: theme.colors.blue }, { children: "+ Add yearly data" }))), _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: columns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: sortBy(companyYearlyData, "year").map(function (datum) {
                            return (_jsx(TR, { children: columns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, companyYearlyData: datum, isInAdminTools: isInAdminTools, editCompanyYearlyData: isInAdminTools ? editCompanyYearlyData : undefined, removeCompanyYearlyData: isInAdminTools ? removeCompanyYearlyData : undefined }) }, c.key)); }) }, datum.id));
                        }) })] })] })));
};
