var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, PageContent, SmallButton, TD, TH, TR, Table, TitleWithExtraContent, } from "../../common-styled-components";
import { isValidUserInput } from "../compliance-pool-types";
import useFetch from "../../../hooks/useFetch";
import useEdit from "../../../hooks/useEdit";
import { Modal } from "../common/Modal";
import { LoaderStatus } from "../../../util/apiUtils";
import { isNullish } from "../../../util/isNullish";
var tableColumns = [
    {
        key: "username",
        label: "Email",
    },
    {
        key: "name",
        label: "Name",
    },
    {
        key: "company_id",
        label: "Company",
    },
    {
        key: "tools",
        label: "Tools",
    },
];
var CellContent = function (_a) {
    var _b, _c, _d;
    var user = _a.user, contentKey = _a.contentKey, editUser = _a.editUser, removeUser = _a.removeUser, companies = _a.companies;
    switch (contentKey) {
        case "tools":
            return (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removeUser(user); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editUser(user); } }, { children: "Edit" }))] }));
        case "company_id":
            return (_jsx("div", { children: (_c = (_b = companies.find(function (c) { return c.id === user.company_id; })) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : user.company_id }));
        case "name":
            return _jsx("div", { children: (_d = user.name) !== null && _d !== void 0 ? _d : "" });
        default:
            return _jsx("div", { children: user[contentKey] });
    }
};
export default (function () {
    var _a = useFetch("admin/users.json", undefined), users = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    var overview = useFetch("admin/overview.json", undefined)[0];
    var _b = useEdit("admin/user.json", refresh), editStatus = _b[0], executeEdit = _b[1];
    var _c = useState({}), modal = _c[0], setModal = _c[1];
    var addUser = function () {
        setModal({
            type: "add",
            value: undefined,
        });
    };
    var editUser = function (user) {
        setModal({
            type: "edit",
            value: user,
        });
    };
    var removeUser = function (user) {
        setModal({
            type: "remove",
            value: user,
        });
    };
    var submit = function (type, value) {
        setModal({});
        var name = !isNullish(value.name) && value.name !== "" ? value.name : null;
        if (type === "add") {
            if (isValidUserInput(value)) {
                var data = {
                    name: name,
                    username: value.username,
                    company_id: value.company_id,
                    id: null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name or user id");
            }
        }
        if (type === "edit") {
            if (isValidUserInput(value) && !isNullish(value.id)) {
                var data = {
                    name: name,
                    username: value.username,
                    company_id: value.company_id,
                    id: value.id,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [modal.type && (_jsx(Modal, { type: "user", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit })), _jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(Button, __assign({ onClick: addUser }, { children: "+ Add" })) }, { children: "Users" })), _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [tableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }), " "] }) }), _jsx("tbody", { children: users.status === LoaderStatus.Success &&
                            overview.status === LoaderStatus.Success &&
                            overview.data.companies &&
                            users.data.map(function (user) { return (_jsx(TR, { children: tableColumns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, user: user, removeUser: removeUser, editUser: editUser, companies: overview.data.companies }) }, c.key)); }) }, user.id)); }) })] })] })));
});
