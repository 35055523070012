var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import App from "../App";
import MainPage from "../components/MainPage";
import Test from "../components/Test";
import CompliancePools from "../components/compliance-pools/client";
import AdminTools from "../components/compliance-pools/admin/AdminTools";
import ShipAdmin from "../components/compliance-pools/admin/ShipAdmin";
import ShipsAdmin from "../components/compliance-pools/admin/ShipsAdmin";
import ShipsClient from "../components/compliance-pools/client/ShipsClient";
import VoyageAdmin from "../components/compliance-pools/admin/VoyageAdmin";
import FleetCalculator from "../components/fleet-calculator";
import SurplusCalculator from "../components/surplus-calculator";
import Voyage from "../components/voyage";
import PoolAdmin from "../components/compliance-pools/admin/PoolAdmin";
import FleetAdmin from "../components/compliance-pools/admin/FleetAdmin";
import PoolsAdmin from "../components/compliance-pools/admin/PoolsAdmin";
import TransactionsAdmin from "../components/compliance-pools/admin/TransactionsAdmin";
import CompaniesAdmin from "../components/compliance-pools/admin/CompaniesAdmin";
import FleetsAdmin from "../components/compliance-pools/admin/FleetsAdmin";
import UsersAdmin from "../components/compliance-pools/admin/UsersAdmin";
import { ProtectedRoute } from "../components/ProtectedRoute";
import TestDataTools from "../components/compliance-pools/admin/TestDataTools";
import PoolClient from "../components/compliance-pools/client/PoolClient";
import ComplianceTools from "../components/compliance-pools/client/ComplianceTools";
import ShipClient from "../components/compliance-pools/client/ShipClient";
import CompanyAdmin from "../components/compliance-pools/admin/CompanyAdmin";
import MonthlyReportAdmin from "../components/compliance-pools/admin/MonthlyReportAdmin";
export var routeNames = {
    main: "/",
    voyage: "/voyage-calculator",
    fleetCalculator: "/fleet-calculator",
    surplusCalculator: "/surplus-calculator",
    complianceTools: "/compliance",
    complianceRoot: "/compliance",
    ships: "ships",
    ship: "ship",
    pool: "pool",
    fleet: "fleet",
    companiesAdmin: "companies",
    companyAdmin: "company",
    monthlyReportAdmin: "monthly-report",
    fleetsAdmin: "fleets",
    fleetAdmin: "fleet",
    usersAdmin: "users",
    transactionsAdmin: "transactions",
    login: "/login",
    test: "/test",
    admin: "/compliance/admin",
    testDataTools: "test-data-tools",
    shipsAdmin: "ships",
    shipAdmin: "ship",
    voyageAdmin: "voyage",
    poolsAdmin: "pools",
    poolAdmin: "pool",
};
export var routes = [
    {
        path: routeNames.main,
        element: _jsx(App, {}),
        children: [
            {
                path: routeNames.voyage,
                element: (_jsx(ProtectedRoute, { children: _jsx(Voyage, {}) })),
            },
            {
                path: routeNames.test,
                element: (_jsx(ProtectedRoute, __assign({ needsAdmin: true }, { children: _jsx(Test, {}) }))),
            },
            {
                path: routeNames.fleetCalculator,
                element: _jsx(FleetCalculator, {}),
            },
            {
                path: routeNames.surplusCalculator,
                element: _jsx(SurplusCalculator, {}),
            },
            {
                path: routeNames.complianceTools,
                element: (_jsx(ProtectedRoute, { children: _jsx(ComplianceTools, {}) })),
                children: [
                    {
                        path: routeNames.complianceRoot,
                        element: _jsx(CompliancePools, {}),
                    },
                    {
                        path: routeNames.ships,
                        element: _jsx(ShipsClient, {}),
                    },
                    {
                        path: "".concat(routeNames.ship, "/:shipId"),
                        element: _jsx(ShipClient, {}),
                    },
                    {
                        path: "".concat(routeNames.pool, "/:poolId"),
                        element: _jsx(PoolClient, {}),
                    },
                ],
            },
            {
                path: routeNames.admin,
                element: (_jsx(ProtectedRoute, __assign({ needsAdmin: true }, { children: _jsx(AdminTools, {}) }))),
                children: [
                    {
                        path: routeNames.shipsAdmin,
                        element: _jsx(ShipsAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.shipAdmin, "/:shipId"),
                        element: _jsx(ShipAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.voyageAdmin, "/:voyageId"),
                        element: _jsx(VoyageAdmin, {}),
                    },
                    {
                        path: routeNames.poolsAdmin,
                        element: _jsx(PoolsAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.poolAdmin, "/:poolId"),
                        element: _jsx(PoolAdmin, {}),
                    },
                    {
                        path: routeNames.transactionsAdmin,
                        element: _jsx(TransactionsAdmin, {}),
                    },
                    {
                        path: routeNames.companiesAdmin,
                        element: _jsx(CompaniesAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.companyAdmin, "/:companyId"),
                        element: _jsx(CompanyAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.monthlyReportAdmin, "/:companyId/pool/:poolId/year/:year/month/:month"),
                        element: _jsx(MonthlyReportAdmin, {}),
                    },
                    {
                        path: routeNames.fleetsAdmin,
                        element: _jsx(FleetsAdmin, {}),
                    },
                    {
                        path: routeNames.usersAdmin,
                        element: _jsx(UsersAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.fleetAdmin, "/:fleetId"),
                        element: _jsx(FleetAdmin, {}),
                    },
                    {
                        path: routeNames.testDataTools,
                        element: _jsx(TestDataTools, {}),
                    },
                ],
            },
            {
                path: routeNames.main,
                element: _jsx(MainPage, {}),
            },
        ],
    },
];
