import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Selector, SelectorLabel } from "../../../common-styled-components";
import { TextInput } from "../../../generic/TextInput";
import useFetch from "../../../../hooks/useFetch";
import { LoaderStatus } from "../../../../util/apiUtils";
import { NumberInput } from "../../../generic/NumberInput";
var emptyOption = { label: "Select ship", value: null };
export var CompanyYearlyDataEditor = function (_a) {
    var _b, _c, _d, _e;
    var state = _a.state, updateValue = _a.updateValue, modalType = _a.modalType;
    var overview = useFetch("admin/overview.json", undefined)[0];
    if (modalType === "remove") {
        return _jsx("div", { children: "Remove yearly data?" });
    }
    var shipOptions = [emptyOption].concat(overview.status === LoaderStatus.Success
        ? overview.data.ships.map(function (c) { return ({
            label: c.name,
            value: c.id,
        }); })
        : []);
    return (_jsxs(_Fragment, { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Year" }), _jsx(NumberInput, { value: (_b = state.year) !== null && _b !== void 0 ? _b : "", onChange: function (newValue) { return updateValue("year", newValue); } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Compliance selling price" }), _jsx(NumberInput, { value: (_c = state.compliance_selling_price) !== null && _c !== void 0 ? _c : "", onChange: function (newValue) {
                            return updateValue("compliance_selling_price", newValue);
                        } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Compliance buying price" }), _jsx(NumberInput, { value: (_d = state.compliance_buying_price) !== null && _d !== void 0 ? _d : "", onChange: function (newValue) {
                            return updateValue("compliance_buying_price", newValue);
                        } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Currency" }), _jsx(TextInput, { value: (_e = state.currency) !== null && _e !== void 0 ? _e : "", onChange: function (newValue) { return updateValue("currency", newValue); } })] })] }));
};
