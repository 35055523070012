var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useParams } from "react-router-dom";
import { Bold, Grid, PageContent, PTight, Row, SectionTitle, SeparatedRow, SmallSubtitle, WideTextContent, } from "../../common-styled-components";
import useFetch from "../../../hooks/useFetch";
import { LoaderStatus } from "../../../util/apiUtils";
import ComplianceKPI from "../common/ComplianceKPI";
import { getCompliance } from "../../../util/compliance/compliance";
import { useMetadata } from "../../../hooks/useMetadata";
import { combineFuels, getFuelColor, getTransactionPOI, scaleEmissions, } from "../util";
import { theme } from "../../../theme";
import LineChart from "../LineChart";
import { useEffect, useState } from "react";
import { AltCalculations } from "../common/AltCalculations";
import { log } from "../../../util/log";
import { TransactionTable } from "../common/tables/TransactionTable";
import { VoyageTable } from "../common/tables/VoyageTable";
import { routeNames } from "../../../util/routes";
import { defaultYear } from "../../../util/constants";
var mockFuelPrices = {
    1: 800,
    3: 600,
    24: 1010,
};
var currentYear = defaultYear;
export default (function () {
    var shipId = useParams().shipId;
    var metadata = useMetadata()[0];
    var ship = useFetch("ship/ship.json", JSON.stringify({
        ship_id: Number(shipId),
        year: currentYear,
    }))[0];
    var voyages = useFetch("voyage/voyages.json", JSON.stringify({
        ship_id: Number(shipId),
        year: defaultYear,
    }))[0];
    var _a = useFetch("ship/ship_fuel_usage.json", JSON.stringify({ ship_id: Number(shipId), year: defaultYear })), usageAnalysis = _a[0], _setAnalysisUrl = _a[1], _setAnalysisBody = _a[2], refreshAnalysis = _a[3];
    var _b = useFetch("calculator/cost-comparison", undefined, true), bioDiesel = _b[0], _setBioDieselUrl = _b[1], setBioDieselBody = _b[2];
    var _c = useState(true), showNonAdjustedData = _c[0], setShowNonAdjustedData = _c[1];
    useEffect(function () {
        if (usageData &&
            usageData.ship.forecast.total.adjusted_emission_balance_ton < 0 &&
            metadata &&
            bioDiesel.status === LoaderStatus.Idle) {
            var bodyBasis = {
                fleet_size: 1,
                year: defaultYear,
                fuels: usageData.ship.forecast.forecast_fuels.map(function (fuelAmount) {
                    var _a;
                    return {
                        engine_type: fuelAmount.engine_type,
                        fuel_type: fuelAmount.fuel,
                        foc: fuelAmount.amount,
                        fuel_price: (_a = mockFuelPrices[fuelAmount.fuel]) !== null && _a !== void 0 ? _a : metadata.fuels[fuelAmount.fuel].price,
                    };
                }),
            };
            var iceFuels = [24];
            var bodyB30 = __assign(__assign({}, bodyBasis), { comparison_fuels: iceFuels.map(function (f) {
                    var _a;
                    return ({
                        engine_change_cost: 0,
                        engine_type: 1,
                        fuel_price: (_a = mockFuelPrices[f]) !== null && _a !== void 0 ? _a : metadata.fuels[f].price,
                        fuel_type: f,
                        engine_efficiency: metadata["efficiency-fuel-engine"][f][1],
                        intensity: metadata["intensity-fuel-engine"][f][1],
                    });
                }) });
            setBioDieselBody(JSON.stringify(bodyB30));
        }
    }, [usageAnalysis.status, metadata]);
    if (ship.status !== LoaderStatus.Success ||
        voyages.status !== LoaderStatus.Success ||
        usageAnalysis.status !== LoaderStatus.Success ||
        !metadata) {
        return _jsx(PageContent, { children: "Loading\u2026" });
    }
    var shipData = ship.data;
    var voyageData = voyages.data;
    var usageData = usageAnalysis.data;
    if (!shipData) {
        return _jsx(PageContent, { children: "Ship not found!" });
    }
    var analysis = usageData.ship.analysis;
    var transactions = usageData.ship.transactions;
    var poiData = transactions.from
        .map(function (t) { return getTransactionPOI(t, "from"); })
        .concat(transactions.to.map(function (t) { return getTransactionPOI(t, "to"); }));
    log([
        {
            label: "Metadata",
            content: metadata,
        },
        {
            label: "Usage analysis",
            content: usageData,
        },
        {
            label: "BioDiesel calculation",
            content: bioDiesel.status === LoaderStatus.Success ? bioDiesel.data : "loading",
        },
    ]);
    var minCellWidth = 420;
    var forecastsAvailable = usageData.ship.forecast.forecast !== undefined &&
        usageData.ship.forecast.total !== undefined;
    var forecastFuels = forecastsAvailable
        ? combineFuels(usageData.ship.forecast.forecast_fuels_total)
        : undefined;
    var forecastDate = forecastsAvailable
        ? new Date(usageData.ship.forecast.end)
        : undefined;
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(SectionTitle, { children: shipData.name }), _jsxs(SeparatedRow, { children: [_jsxs("div", __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsxs(PTight, { children: [_jsx(Bold, { children: "Vessel name: " }), shipData.name] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "IMO: " }), shipData.imo] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "Pool: " }), _jsx(Link, __assign({ to: "".concat(routeNames.complianceRoot, "/").concat(routeNames.pool, "/").concat(shipData.pool_id) }, { children: shipData.pool_name }))] })] })), _jsxs(Row, { children: [_jsx(ComplianceKPI, { compliance: getCompliance(metadata, defaultYear, analysis.intensities.total.adjusted_intensity_gCO2e_per_MJ), title: "Carbon intensity", unit: "gCO\u2082e/MJ", value: analysis.intensities.total.adjusted_intensity_gCO2e_per_MJ }), _jsx(ComplianceKPI, { compliance: getCompliance(metadata, defaultYear, analysis.intensities.total.adjusted_intensity_gCO2e_per_MJ), title: "Carbon unit balance", unit: analysis.intensities.total.adjusted_emission_balance_ton < 0
                                    ? "tCO₂e deficit"
                                    : "tCO₂e to spare", value: scaleEmissions(analysis.intensities.total.adjusted_emission_balance_ton) }), bioDiesel.status === LoaderStatus.Success && bioDiesel.data && (_jsx(AltCalculations, { calculation: bioDiesel.data, analysis: usageData, metadata: metadata }))] })] }), _jsxs(Grid, __assign({ minCellWidth: minCellWidth }, { children: [_jsx("div", { children: _jsx(LineChart, { title: "Ship carbon intensity", unit: "gCO\u2082e/MJ", data: (showNonAdjustedData && poiData.length
                                ? [
                                    {
                                        name: "Intensity without transactions",
                                        color: theme.colors.grey(2),
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.intensity_gCO2e_per_MJ,
                                        }); }),
                                        prediction: forecastsAvailable && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: usageData.ship.forecast.total
                                                    .intensity_gCO2e_per_MJ,
                                            }
                                            : undefined,
                                    },
                                ]
                                : []).concat([
                                {
                                    name: "Intensity",
                                    color: theme.colors.blackText,
                                    data: analysis.intensities.cumulative.map(function (d) { return ({
                                        date: new Date(d.date),
                                        value: d.intensity.adjusted_intensity_gCO2e_per_MJ,
                                    }); }),
                                    prediction: forecastsAvailable && forecastDate
                                        ? {
                                            date: forecastDate,
                                            value: usageData.ship.forecast.total
                                                .adjusted_intensity_gCO2e_per_MJ,
                                        }
                                        : undefined,
                                },
                            ]), poiData: poiData, poiDataSetName: "Intensity", limits: [
                                {
                                    label: "".concat(defaultYear, " limit"),
                                    value: metadata.intensity[defaultYear],
                                    color: theme.colors.red,
                                },
                            ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Carbon balance", unit: "tCO\u2082e to spare", data: (showNonAdjustedData && poiData.length
                                ? [
                                    {
                                        name: "Balance without transactions",
                                        color: theme.colors.grey(2),
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.emission_balance_ton,
                                        }); }),
                                        prediction: forecastsAvailable && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: usageData.ship.forecast.total
                                                    .emission_balance_ton,
                                            }
                                            : undefined,
                                    },
                                ]
                                : []).concat([
                                {
                                    name: "Carbon unit balance",
                                    color: theme.colors.blackText,
                                    data: analysis.intensities.cumulative.map(function (d) { return ({
                                        date: new Date(d.date),
                                        value: d.intensity.adjusted_emission_balance_ton,
                                    }); }),
                                    prediction: forecastsAvailable && forecastDate
                                        ? {
                                            date: forecastDate,
                                            value: usageData.ship.forecast.total
                                                .adjusted_emission_balance_ton,
                                        }
                                        : undefined,
                                },
                            ]), poiData: poiData, poiDataSetName: "Carbon unit balance" }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative emissions", unit: "tCO\u2082e", data: (showNonAdjustedData && poiData.length
                                ? [
                                    {
                                        name: "Emissions without transactions",
                                        color: theme.colors.grey(2),
                                        dashed: false,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.co2_emission_ton,
                                        }); }),
                                        prediction: forecastsAvailable && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: usageData.ship.forecast.total.co2_emission_ton,
                                            }
                                            : undefined,
                                    },
                                ]
                                : []).concat([
                                {
                                    name: "Emissions",
                                    color: theme.colors.blackText,
                                    dashed: false,
                                    data: analysis.intensities.cumulative.map(function (d) { return ({
                                        date: new Date(d.date),
                                        value: d.intensity.adjusted_co2_emission_ton,
                                    }); }),
                                    prediction: forecastsAvailable && forecastDate
                                        ? {
                                            date: forecastDate,
                                            value: usageData.ship.forecast.total
                                                .adjusted_co2_emission_ton,
                                        }
                                        : undefined,
                                },
                                {
                                    name: "Emission limit",
                                    color: theme.colors.red,
                                    dashed: true,
                                    data: analysis.intensities.cumulative.map(function (d) { return ({
                                        date: new Date(d.date),
                                        value: d.intensity.emission_limit_ton,
                                    }); }),
                                    prediction: forecastsAvailable && forecastDate
                                        ? {
                                            date: forecastDate,
                                            value: usageData.ship.forecast.total.emission_limit_ton,
                                        }
                                        : undefined,
                                },
                            ]), poiData: poiData, poiDataSetName: "Emissions" }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative fuel use", unit: "t", data: Object.keys(analysis.fuel_spent.cumulative_fuel_timeline).map(function (fuelKey, i) {
                                var _a, _b;
                                var fuelData = analysis.fuel_spent.cumulative_fuel_timeline[fuelKey];
                                return {
                                    name: (_b = (_a = metadata.fuels[fuelData.fuel]) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : "Name not found",
                                    color: getFuelColor(fuelData.fuel),
                                    data: fuelData.timeline.map(function (d) { return ({
                                        date: new Date(d.date),
                                        value: d.amount,
                                    }); }),
                                    prediction: forecastFuels && forecastDate
                                        ? {
                                            date: forecastDate,
                                            value: forecastFuels[fuelKey],
                                        }
                                        : undefined,
                                };
                            }) }) })] })), (transactions === null || transactions === void 0 ? void 0 : transactions.from.length) > 0 && (_jsxs(WideTextContent, __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsxs(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: ["Compliance transactions from ", shipData.name] })), _jsx(TransactionTable, { transactions: transactions.from, complianceYear: defaultYear, poolId: shipData.pool_id, refresh: refreshAnalysis, isInAdminTools: false })] }))), (transactions === null || transactions === void 0 ? void 0 : transactions.to.length) > 0 && (_jsxs(WideTextContent, __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsxs(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: ["Compliance transactions to ", shipData.name] })), _jsx(TransactionTable, { transactions: transactions.to, complianceYear: defaultYear, poolId: shipData.pool_id, refresh: refreshAnalysis, isInAdminTools: false })] }))), _jsxs(WideTextContent, __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: "Reports" })), _jsx(VoyageTable, { voyages: voyageData, metadata: metadata, isInAdminTools: false })] }))] })));
});
