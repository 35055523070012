var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { CenteredRow, Grid, PageContent, SectionTitle, SmallSubtitle, WideTextContent, } from "../../common-styled-components";
import useFetch from "../../../hooks/useFetch";
import { LoaderStatus } from "../../../util/apiUtils";
import { useMetadata } from "../../../hooks/useMetadata";
import ComplianceKPI from "../common/ComplianceKPI";
import { getCompliance } from "../../../util/compliance/compliance";
import LineChart from "../LineChart";
import { theme } from "../../../theme";
import { combineFuels, getFuelColor, getTransactionPOI, scaleEmissions, } from "../util";
import { log } from "../../../util/log";
import { ShipTable } from "../common/tables/ShipTable";
import { TransactionTable } from "../common/tables/TransactionTable";
import { defaultYear } from "../../../util/constants";
export default (function () {
    var poolId = useParams().poolId;
    var _a = useFetch("admin/pools.json", JSON.stringify({
        pool_id: Number(poolId),
        year: defaultYear,
    })), pools = _a[0], _setUrl = _a[1], _setBody = _a[2], _refresh = _a[3];
    var usageAnalysis = useFetch("pool/pool_fuel_usage.json", JSON.stringify({ pool_id: Number(poolId), year: defaultYear }))[0];
    var _b = useFetch("admin/transactions.json", JSON.stringify({
        pool_id: Number(poolId),
        year: defaultYear,
    })), transactions = _b[0], _setTransUrl = _b[1], _setTransPostBody = _b[2], refreshTransactions = _b[3];
    var metadata = useMetadata()[0];
    var poolData = pools.status === LoaderStatus.Success ? pools.data[0] : null;
    var usageData = usageAnalysis.status === LoaderStatus.Success
        ? usageAnalysis.data
        : undefined;
    var analysis = usageData === null || usageData === void 0 ? void 0 : usageData.pool.analysis;
    var transactionData = transactions.status === LoaderStatus.Success ? transactions.data : null;
    var poiData = transactionData === null || transactionData === void 0 ? void 0 : transactionData.map(function (t) {
        return getTransactionPOI(t, "neutral");
    });
    var forecastsAvailable = (usageData === null || usageData === void 0 ? void 0 : usageData.pool.forecast) !== undefined &&
        (usageData === null || usageData === void 0 ? void 0 : usageData.pool.forecast.total) !== undefined;
    var forecastFuels = forecastsAvailable
        ? combineFuels(usageData.pool.forecast.forecast_fuels_total)
        : undefined;
    var forecastDate = forecastsAvailable
        ? new Date(usageData.pool.forecast.end)
        : undefined;
    log([
        {
            label: "Metadata",
            content: metadata,
        },
        {
            label: "Pool data",
            content: poolData !== null && poolData !== void 0 ? poolData : "loading",
        },
        {
            label: "Usage analysis",
            content: usageData !== null && usageData !== void 0 ? usageData : "loading",
        },
    ]);
    var minCellWidth = 420;
    var carbonLimitYear = defaultYear;
    if (poolData && analysis && metadata) {
        return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(SectionTitle, { children: poolData.name }), _jsxs(CenteredRow, __assign({ gap: 7 }, { children: [_jsx(ComplianceKPI, { compliance: getCompliance(metadata, carbonLimitYear, analysis.intensities.total.adjusted_intensity_gCO2e_per_MJ), title: "Carbon intensity", unit: "gCO\u2082e/MJ", value: analysis.intensities.total.adjusted_intensity_gCO2e_per_MJ }), _jsx(ComplianceKPI, { compliance: getCompliance(metadata, carbonLimitYear, analysis.intensities.total.adjusted_intensity_gCO2e_per_MJ), title: "Carbon unit balance", unit: analysis.intensities.total.adjusted_emission_balance_ton < 0
                                ? "tCO₂e deficit"
                                : "tCO₂e to spare", value: scaleEmissions(analysis.intensities.total.adjusted_emission_balance_ton) })] })), _jsxs(Grid, __assign({ minCellWidth: minCellWidth }, { children: [_jsx("div", { children: _jsx(LineChart, { title: "Pool carbon intensity", unit: "gCO\u2082e/MJ", poiData: poiData, data: [
                                    {
                                        name: "Intensity",
                                        color: theme.colors.blackText,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.adjusted_intensity_gCO2e_per_MJ,
                                        }); }),
                                        prediction: forecastsAvailable && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: usageData.pool.forecast.total
                                                    .adjusted_intensity_gCO2e_per_MJ,
                                            }
                                            : undefined,
                                    },
                                ], limits: [
                                    {
                                        label: "".concat(carbonLimitYear, " limit"),
                                        value: metadata.intensity[carbonLimitYear],
                                        color: theme.colors.red,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Carbon unit balance", unit: "-tCO\u2082e", poiData: poiData, data: [
                                    {
                                        name: "Carbon unit balance",
                                        color: theme.colors.blackText,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.emission_balance_ton,
                                        }); }),
                                        prediction: forecastsAvailable && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: usageData.pool.forecast.total
                                                    .adjusted_emission_balance_ton,
                                            }
                                            : undefined,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative emissions", unit: "tCO\u2082e", poiData: poiData, data: [
                                    {
                                        name: "Emissions",
                                        color: theme.colors.blackText,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.co2_emission_ton,
                                        }); }),
                                        prediction: forecastsAvailable && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: usageData.pool.forecast.total
                                                    .adjusted_co2_emission_ton,
                                            }
                                            : undefined,
                                    },
                                    {
                                        name: "Emission limit",
                                        color: theme.colors.red,
                                        dashed: true,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.emission_limit_ton,
                                        }); }),
                                        prediction: forecastsAvailable && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: usageData.pool.forecast.total.emission_limit_ton,
                                            }
                                            : undefined,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative fuel use", unit: "t", data: Object.keys(analysis.fuel_spent.cumulative_fuel_timeline).map(function (fuelKey, i) {
                                    var _a, _b;
                                    var fuelData = analysis.fuel_spent.cumulative_fuel_timeline[fuelKey];
                                    return {
                                        name: (_b = (_a = metadata.fuels[fuelData.fuel]) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : "Name not found",
                                        color: getFuelColor(fuelData.fuel),
                                        data: fuelData.timeline.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.amount,
                                        }); }),
                                        prediction: forecastFuels && forecastDate
                                            ? {
                                                date: forecastDate,
                                                value: forecastFuels[fuelKey],
                                            }
                                            : undefined,
                                    };
                                }) }) })] })), _jsxs(WideTextContent, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: "Ships" })), _jsx(ShipTable, { ships: poolData.ships, metadata: metadata, complianceYear: carbonLimitYear, isInAdminTools: true })] }), transactionData && transactionData.length > 0 && (_jsxs(WideTextContent, __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: "Compliance transactions" })), _jsx(TransactionTable, { transactions: transactionData, complianceYear: carbonLimitYear, poolId: poolData.id, refresh: refreshTransactions, isInAdminTools: true })] })))] })));
    }
    return _jsx(PageContent, { children: "Loading pool\u2026" });
});
