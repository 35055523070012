var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { breakpoints, theme } from "../theme";
export var ArticleMain = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
export var UISection = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  margin: ", " 0 ", " 0;\n"], ["\n  width: 100%;\n  margin: ", " 0 ", " 0;\n"])), theme.spacing(4), theme.spacing(5));
export var TextContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 640px;\n  width: 100%;\n"], ["\n  max-width: 640px;\n  width: 100%;\n"])));
export var WideTextContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 1220px;\n  width: 100%;\n"], ["\n  max-width: 1220px;\n  width: 100%;\n"])));
export var PageContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1400px;\n  margin: ", " auto;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: ", ";\n"], ["\n  width: 100%;\n  max-width: 1400px;\n  margin: ", " auto;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: ", ";\n"])), theme.spacing(3), function (p) { return (p.align === "left" ? "flex-start" : "center"); });
export var FullWidthContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var PageTitle = styled.h1(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  ", ";\n  cursor: pointer;\n"], ["\n  ", ";\n  color: ", ";\n  ", ";\n  cursor: pointer;\n"])), theme.fontTitle, theme.colors.blackText, theme.fontSize(3));
export var ToolTitle = styled.h2(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  ", ";\n"], ["\n  ", ";\n  color: ", ";\n  ", ";\n"])), theme.fontTitle, theme.colors.blackText, theme.fontSize(2));
export var SectionTitle = styled.h3(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n  padding: 0 0 ", " 0;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n"], ["\n  ", ";\n  color: ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n  padding: 0 0 ", " 0;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n"])), theme.fontTitle, theme.colors.blackText, theme.fontSize(1), theme.spacing(3), theme.spacing(0), theme.colors.grey(4));
var TitleRowContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  margin: 0 0 ", " 0;\n  padding: 0 0 ", " 0;\n  width: 100%;\n  border-bottom: ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  ", ";\n  color: ", ";\n  margin: 0 0 ", " 0;\n  padding: 0 0 ", " 0;\n  width: 100%;\n  border-bottom: ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"])), theme.fontTitle, theme.colors.blackText, theme.spacing(2), theme.spacing(0), function (p) {
    return p.showBorder ? "1px solid ".concat(theme.colors.grey(4)) : "none";
});
var SectionTitleContent = styled(SectionTitle)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: inline-block;\n  margin: 0;\n  padding: 0;\n  border: none;\n"], ["\n  display: inline-block;\n  margin: 0;\n  padding: 0;\n  border: none;\n"])));
export var SmallTitle = styled.h3(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  ", ";\n  padding: 0;\n  margin: 0;\n  width: 100%;\n"], ["\n  ", ";\n  color: ", ";\n  ", ";\n  padding: 0;\n  margin: 0;\n  width: 100%;\n"])), theme.fontTitle, theme.colors.blackText, theme.fontSize(1));
export var SmallSubtitle = styled.h4(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  ", ";\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  text-transform: uppercase;\n"], ["\n  ", ";\n  color: ", ";\n  ", ";\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  text-transform: uppercase;\n"])), theme.fontTitle, theme.colors.blackText, theme.fontSize(-1));
var SmallSubtitleContent = styled(SmallSubtitle)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: inline-block;\n  margin: 0;\n  padding: 0;\n  border: none;\n"], ["\n  display: inline-block;\n  margin: 0;\n  padding: 0;\n  border: none;\n"])));
export var TitleWithExtraContent = function (_a) {
    var titleLevel = _a.titleLevel, children = _a.children, extraContent = _a.extraContent;
    var titleContent;
    switch (titleLevel) {
        case "Section":
            titleContent = _jsx(SectionTitleContent, { children: children });
            break;
        case "SmallSubtitle":
            titleContent = _jsx(SmallSubtitleContent, { children: children });
    }
    return (_jsxs(TitleRowContainer, __assign({ showBorder: titleLevel === "Section" }, { children: [titleContent, extraContent] })));
};
export var LeadText = styled.p(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  color: ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n"], ["\n  ", ";\n  ", ";\n  color: ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n"])), theme.fontNormal, theme.fontSize(0), theme.colors.blackText, theme.spacing(3));
export var P = styled.p(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  color: ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n  max-width: 640px;\n  width: 100%;\n"], ["\n  ", ";\n  ", ";\n  color: ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n  max-width: 640px;\n  width: 100%;\n"])), theme.fontNormal, theme.fontSize(0), theme.colors.blackText, theme.spacing(3));
export var PTight = styled(P)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  line-height: 1.2;\n  margin: 0;\n"], ["\n  line-height: 1.2;\n  margin: 0;\n"])));
export var DetailP = styled(P)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), theme.spacing(0));
export var PSmall = styled(P)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  ", ";\n  margin-bottom: ", ";\n"], ["\n  ", ";\n  margin-bottom: ", ";\n"])), theme.fontSize(-1), theme.spacing(0));
export var Ingress = styled(P)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), theme.fontNormal, theme.fontSize(0));
export var ShareLink = styled(P)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  cursor: pointer;\n"], ["\n  ", ";\n  color: ", ";\n  cursor: pointer;\n"])), theme.fontBold, theme.colors.lightBlue);
export var A = styled.a(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: underline;\n  text-decoration-color: ", ";\n\n  &:active {\n    text-decoration-color: ", ";\n  }\n  &:visited {\n    text-decoration-color: ", ";\n  }\n"], ["\n  color: ", ";\n  text-decoration: underline;\n  text-decoration-color: ", ";\n\n  &:active {\n    text-decoration-color: ", ";\n  }\n  &:visited {\n    text-decoration-color: ", ";\n  }\n"])), function (p) { var _a; return (_a = p.color) !== null && _a !== void 0 ? _a : theme.colors.blue; }, function (p) { var _a; return (_a = p.color) !== null && _a !== void 0 ? _a : theme.colors.blue; }, theme.colors.blackText, function (p) { var _a; return (_a = p.color) !== null && _a !== void 0 ? _a : theme.colors.blue; });
export var ButtonLikeA = styled.a(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", " ", ";\n  color: white;\n  ", ";\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  flex-grow: 0;\n  flex-shrink: 1;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n  display: inline-block;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  transition: all 0.18s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n  text-decoration: none;\n\n  & + & {\n    margin-left: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    color: white;\n  }\n"], ["\n  background-color: ", ";\n  padding: ", " ", ";\n  color: white;\n  ", ";\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  flex-grow: 0;\n  flex-shrink: 1;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n  display: inline-block;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  transition: all 0.18s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n  text-decoration: none;\n\n  & + & {\n    margin-left: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    color: white;\n  }\n"])), theme.colors.blue, theme.spacing(0), theme.spacing(2), theme.fontBold, theme.fontSize(-1), theme.colors.blue, theme.spacing(2), theme.colors.darkBlue);
export var StrippedLink = styled(Link)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  text-decoration: none;\n  text-decoration-thickness: 0;\n  color: initial;\n  flex-grow: 0;\n  display: inline-block;\n  position: relative;\n  ", ";\n  cursor: pointer;\n"], ["\n  text-decoration: none;\n  text-decoration-thickness: 0;\n  color: initial;\n  flex-grow: 0;\n  display: inline-block;\n  position: relative;\n  ", ";\n  cursor: pointer;\n"])), theme.fontBold);
export var Li = styled.li(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  color: ", ";\n  line-height: 1.6;\n  margin-bottom: ", ";\n"], ["\n  ", ";\n  ", ";\n  color: ", ";\n  line-height: 1.6;\n  margin-bottom: ", ";\n"])), theme.fontNormal, theme.fontSize(0), theme.colors.blackText, theme.spacing(1));
export var Bold = styled.span(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), theme.fontBold);
export var Italic = styled.span(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  font-style: italic;\n"], ["\n  font-style: italic;\n"])));
export var GraphTitle = styled.h4(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  max-width: 640px;\n  ", ";\n  ", ";\n  margin: ", " 0 ", " 0;\n"], ["\n  max-width: 640px;\n  ", ";\n  ", ";\n  margin: ", " 0 ", " 0;\n"])), theme.fontLabelBold, theme.fontSize(0), theme.spacing(3), theme.spacing(1));
export var GraphSubTitle = styled.h5(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  max-width: 640px;\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"], ["\n  max-width: 640px;\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"])), theme.fontNormal, theme.fontSize(-1), theme.spacing(1));
export var GraphLabel = styled.text(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), theme.fontBold);
export var GraphSVG = styled.svg(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), theme.spacing(2));
export var Caption = styled.div(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  width: 100%;\n  ", ";\n  ", ";\n  color: ", ";\n"], ["\n  width: 100%;\n  ", ";\n  ", ";\n  color: ", ";\n"])), theme.fontNormal, theme.fontSize(-1), theme.colors.darkBlue);
export var TextComment = styled.span(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  color: red;\n"], ["\n  color: red;\n"])));
export var Grid = styled.div(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  display: grid;\n  width: 100%;\n  grid-template-columns: repeat(\n    auto-fit,\n    minmax(\n      ", ",\n      ", "\n    )\n  );\n  grid-column-gap: ", ";\n  grid-row-gap: ", ";\n  margin: ", " 0;\n  align-items: start;\n\n  @media (max-width: ", "px) {\n    grid-template-columns: 100%;\n  }\n"], ["\n  display: grid;\n  width: 100%;\n  grid-template-columns: repeat(\n    auto-fit,\n    minmax(\n      ", ",\n      ", "\n    )\n  );\n  grid-column-gap: ", ";\n  grid-row-gap: ", ";\n  margin: ", " 0;\n  align-items: start;\n\n  @media (max-width: ", "px) {\n    grid-template-columns: 100%;\n  }\n"])), function (p) {
    return p.minCellWidth !== undefined ? "".concat(p.minCellWidth, "px") : "260px";
}, function (p) {
    return p.maxCellWidth !== undefined
        ? "".concat(p.maxCellWidth, "px")
        : p.full
            ? "100%"
            : "1fr";
}, theme.spacing(4), theme.spacing(3), theme.spacing(2), breakpoints.mobilePlus);
export var Legend = styled.div(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  margin: 0;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  margin-bottom: ", ";\n"], ["\n  margin: 0;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  margin-bottom: ", ";\n"])), theme.spacing(4));
var LegendItemElement = styled.div(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: nowrap;\n  margin-right: ", ";\n  margin-top: ", ";\n  ", ";\n  ", ";\n  color: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: nowrap;\n  margin-right: ", ";\n  margin-top: ", ";\n  ", ";\n  ", ";\n  color: ", ";\n"])), theme.spacing(2), theme.spacing(1), theme.fontSize(-1), theme.fontLabelBold, function (p) { return (p.textColor ? p.textColor : theme.colors.blackText); });
var ColorMarker = styled.div(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  width: 12px;\n  height: 12px;\n  background-color: ", ";\n  margin-right: ", ";\n  border-radius: 50%;\n"], ["\n  width: 12px;\n  height: 12px;\n  background-color: ", ";\n  margin-right: ", ";\n  border-radius: 50%;\n"])), function (p) { return p.color; }, theme.spacing(0));
export var LegendItem = function (props) { return (_jsxs(LegendItemElement, __assign({ textColor: props.textColor }, { children: [_jsx(ColorMarker, { color: props.color }), _jsx("div", { children: props.text })] }))); };
export var Button = styled.button(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", " ", ";\n  color: white;\n  ", ";\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  flex-grow: 0;\n  flex-shrink: 1;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n  display: inline-block;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  transition: all 0.18s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n\n  & + & {\n    margin-left: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    color: white;\n  }\n\n  &:disabled {\n    background-color: ", ";\n    border: 1px solid ", ";\n    color: ", ";\n    cursor: not-allowed;\n  }\n"], ["\n  background-color: ", ";\n  padding: ", " ", ";\n  color: white;\n  ", ";\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  flex-grow: 0;\n  flex-shrink: 1;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n  display: inline-block;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  transition: all 0.18s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n\n  & + & {\n    margin-left: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    color: white;\n  }\n\n  &:disabled {\n    background-color: ", ";\n    border: 1px solid ", ";\n    color: ", ";\n    cursor: not-allowed;\n  }\n"])), theme.colors.blue, theme.spacing(0), theme.spacing(2), theme.fontBold, theme.fontSize(-1), theme.colors.blue, theme.spacing(2), theme.colors.darkBlue, theme.colors.grey(3), theme.colors.grey(2), theme.colors.grey(2));
export var SecondaryButton = styled(Button)(templateObject_39 || (templateObject_39 = __makeTemplateObject(["\n  background-color: white;\n  color: ", ";\n  border: none;\n\n  &:disabled {\n    background-color: white;\n    color: ", ";\n    border: none;\n  }\n"], ["\n  background-color: white;\n  color: ", ";\n  border: none;\n\n  &:disabled {\n    background-color: white;\n    color: ", ";\n    border: none;\n  }\n"])), function (p) { var _a; return (_a = p.color) !== null && _a !== void 0 ? _a : theme.colors.darkBlue; }, theme.colors.grey(2));
export var NavigationButton = styled.button(templateObject_40 || (templateObject_40 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", " ", ";\n  color: ", ";\n  ", ";\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  flex-grow: 0;\n  flex-shrink: 1;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n  display: inline-block;\n  border-radius: 6px 6px 0px 0px;\n  border: 1px solid ", ";\n  border-bottom: none;\n  transition: all 0.18s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n\n  & + & {\n    margin-left: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    color: white;\n  }\n"], ["\n  background-color: ", ";\n  padding: ", " ", ";\n  color: ", ";\n  ", ";\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  flex-grow: 0;\n  flex-shrink: 1;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n  display: inline-block;\n  border-radius: 6px 6px 0px 0px;\n  border: 1px solid ", ";\n  border-bottom: none;\n  transition: all 0.18s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n\n  & + & {\n    margin-left: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    color: white;\n  }\n"])), function (p) {
    return p.selected ? theme.colors.darkBlue : "transparent";
}, theme.spacing(1), theme.spacing(2), function (p) { return (p.selected ? "white" : theme.colors.darkBlue); }, theme.fontBold, theme.fontSize(-1), theme.colors.darkBlue, theme.spacing(2), theme.colors.darkBlue);
export var SmallButton = styled(Button)(templateObject_41 || (templateObject_41 = __makeTemplateObject(["\n  background-color: transparent;\n  color: ", ";\n  padding: 2px 4px;\n  ", ";\n  border: none;\n"], ["\n  background-color: transparent;\n  color: ", ";\n  padding: 2px 4px;\n  ", ";\n  border: none;\n"])), theme.colors.darkBlue, theme.fontNormal);
export var LinkButton = function (props) { return (_jsx(StrippedLink, __assign({ to: props.to }, { children: _jsx(Button, { children: props.children }) }))); };
export var Row = styled.div(templateObject_42 || (templateObject_42 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: ", ";\n"])), function (p) { return (p.gap !== undefined ? theme.spacing(p.gap) : "0"); });
export var CenteredRow = styled(Row)(templateObject_43 || (templateObject_43 = __makeTemplateObject(["\n  width: 100%;\n  justify-content: center;\n"], ["\n  width: 100%;\n  justify-content: center;\n"])));
export var SeparatedRow = styled(Row)(templateObject_44 || (templateObject_44 = __makeTemplateObject(["\n  width: 100%;\n  justify-content: space-between;\n"], ["\n  width: 100%;\n  justify-content: space-between;\n"])));
export var GraphImage = styled.img(templateObject_45 || (templateObject_45 = __makeTemplateObject(["\n  width: 100%;\n  ", "\n"], ["\n  width: 100%;\n  ", "\n"])), function (p) { return (p.paddingTop ? "padding-top: ".concat(p.paddingTop, "px;") : ""); });
export var Selector = styled.div(templateObject_46 || (templateObject_46 = __makeTemplateObject(["\n  margin-top: ", ";\n  display: flex;\n  width: 100%;\n  ", ";\n  ", "\n"], ["\n  margin-top: ", ";\n  display: flex;\n  width: 100%;\n  ", ";\n  ", "\n"])), theme.spacing(2), theme.fontSize(-1), function (p) {
    return p.horizontal
        ? "\n    flex-direcion: row;\n    align-items: center;\n  "
        : "\n    flex-direction: column;\n  ";
});
export var SelectorLabel = styled.label(templateObject_47 || (templateObject_47 = __makeTemplateObject(["\n  width: ", ";\n  margin-", ": ", "};\n  color: ", ";\n"], ["\n  width: ", ";\n  margin-", ": ", "};\n  color: ", ";\n"])), function (p) { return (p.horizontal ? "auto" : "100%"); }, function (p) { return (p.horizontal ? "right" : "bottom"); }, theme.spacing(0), function (p) { return (p.disabled ? theme.colors.grey(3) : theme.colors.blackText); });
export var Figure = styled.figure(templateObject_48 || (templateObject_48 = __makeTemplateObject(["\n  margin: 0 0 0 0;\n  width: 100%;\n"], ["\n  margin: 0 0 0 0;\n  width: 100%;\n"])));
export var Table = styled.table(templateObject_49 || (templateObject_49 = __makeTemplateObject(["\n  overflow: scroll;\n  width: 100%;\n  border-spacing: 1px;\n"], ["\n  overflow: scroll;\n  width: 100%;\n  border-spacing: 1px;\n"])));
export var TH = styled.th(templateObject_50 || (templateObject_50 = __makeTemplateObject(["\n  text-align: left;\n  padding: ", ";\n  ", ";\n  background-color: ", ";\n  color: white;\n"], ["\n  text-align: left;\n  padding: ", ";\n  ", ";\n  background-color: ", ";\n  color: white;\n"])), theme.spacing(3), theme.fontSize(-1), theme.colors.blue);
export var TR = styled.tr(templateObject_51 || (templateObject_51 = __makeTemplateObject(["\n  &:nth-child(even) {\n    background-color: ", ";\n  }\n  ", ";\n"], ["\n  &:nth-child(even) {\n    background-color: ", ";\n  }\n  ", ";\n"])), theme.colors.grey(4), theme.fontSize(-1));
export var TD = styled.td(templateObject_52 || (templateObject_52 = __makeTemplateObject(["\n  text-align: left;\n  padding: ", " ", ";\n  ", ";\n"], ["\n  text-align: left;\n  padding: ", " ", ";\n  ", ";\n"])), theme.spacing(1), theme.spacing(3), theme.fontSize(-1));
export var ComplianceIcon = styled.img(templateObject_53 || (templateObject_53 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  margin-right: ", ";\n"], ["\n  width: 16px;\n  height: 16px;\n  margin-right: ", ";\n"])), theme.spacing(1));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38, templateObject_39, templateObject_40, templateObject_41, templateObject_42, templateObject_43, templateObject_44, templateObject_45, templateObject_46, templateObject_47, templateObject_48, templateObject_49, templateObject_50, templateObject_51, templateObject_52, templateObject_53;
