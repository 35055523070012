var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { theme } from "../../theme";
import { GraphTitle, Grid, Legend, LegendItem, P, SectionTitle, UISection, } from "../common-styled-components";
import CompareBarChart, { getComparisonColor, } from "../custom/CompareBarChart";
import { PrivateContentVeil } from "../generic/PrivateContentVeil";
import FleetMixChart from "./FleetMixChart";
import TimelineChart from "../custom/TimelineChart";
import { calculateResults, getPricePerGJ, getYearValues, } from "../../util/calculatorUtils";
import { MAX_SMALL_CELL_WIDTH, MIN_MEDIUM_CELL_WIDTH } from "../constants";
import { every, some } from "lodash";
import { getCarbonIntensity } from "../../util/carbonIntensity";
export default (function (_a) {
    var _b, _c, _d;
    var params = _a.params, fuelTypes = _a.fuelTypes, calculation = _a.calculation, metadata = _a.metadata, texts = _a.texts;
    var resultDetails = useDeepMemo(function () { return calculateResults(params, fuelTypes, calculation); }, [calculation, params, fuelTypes]);
    var originalFuel = resultDetails.originalFuel, alternativeFuels = resultDetails.alternativeFuels, cumulativeCosts = resultDetails.cumulativeCosts, cumulativeEmissions = resultDetails.cumulativeEmissions, originalPathTimeline = resultDetails.originalPathTimeline, fuelMixTimelines = resultDetails.fuelMixTimelines, lastAccumulationYear = resultDetails.lastAccumulationYear, complianceData = resultDetails.complianceData, maxCost = resultDetails.maxCost;
    if (alternativeFuels.length !== calculation.comparisons.length) {
        return _jsx("div", {});
    }
    var hasMultipleComparisons = calculation.comparisons.length > 1;
    var minTimelineCellWidth = hasMultipleComparisons
        ? MIN_MEDIUM_CELL_WIDTH
        : 640;
    var cost_per_exceeded_emission_ton = {
        name: "Cost per exceeded emission ton (original fuel)",
        value: (_d = (_c = (_b = calculation.original_fuel.timeline
            .find(function (d) { return d.year === 2025; })) === null || _b === void 0 ? void 0 : _b.cost_per_exceeded_emission_ton.find(function (d) { return d.with_emissions === false; })) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0,
        color: getComparisonColor(0),
    };
    return (_jsx("div", { children: calculation && resultDetails && (_jsxs(_Fragment, { children: [_jsxs(UISection, { children: [_jsx(SectionTitle, { children: "Overview" }), _jsxs(Grid, __assign({ maxCellWidth: MAX_SMALL_CELL_WIDTH }, { children: [(originalFuel === null || originalFuel === void 0 ? void 0 : originalFuel.fuel_cost_per_ton) !== undefined &&
                                    every(params.comparison_fuels, function (f) { return f.fuel_price !== undefined; }) && (_jsx("div", { children: _jsx(CompareBarChart, { unit: "USD/t", valueSeries: [
                                            {
                                                title: "Fuel price",
                                                selectorLabel: "Fuel price",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: originalFuel.fuel_cost_per_ton,
                                                    },
                                                ].concat(alternativeFuels.map(function (af, i) { return ({
                                                    name: af.description,
                                                    color: getComparisonColor(i + 1),
                                                    value: params.comparison_fuels[i].fuel_price,
                                                }); })),
                                            },
                                        ] }) })), _jsx("div", { children: _jsx(CompareBarChart, { unit: "MJ/g", maxDigits: 3, valueSeries: [
                                            {
                                                title: "LCV",
                                                selectorLabel: "LCV",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: originalFuel.energy_content,
                                                    },
                                                ].concat(alternativeFuels.map(function (af, i) { return ({
                                                    name: af.description,
                                                    color: getComparisonColor(i + 1),
                                                    value: af.energy_content,
                                                }); })),
                                            },
                                        ] }) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "USD/GJ", valueSeries: [
                                            {
                                                title: "Energy cost",
                                                selectorLabel: "Energy cost",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: getPricePerGJ(originalFuel.fuel_cost_per_ton, originalFuel.energy_content),
                                                    },
                                                ].concat(alternativeFuels.map(function (af, i) {
                                                    var _a;
                                                    return ({
                                                        name: af.description,
                                                        color: getComparisonColor(i + 1),
                                                        value: getPricePerGJ(((_a = params.comparison_fuels[i]) === null || _a === void 0 ? void 0 : _a.fuel_price) || af.price, af.energy_content),
                                                    });
                                                })),
                                            },
                                        ] }) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "gCO2e/MJ", valueSeries: [
                                            {
                                                title: "Carbon intensity",
                                                selectorLabel: "Carbon intensity",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: calculation.carbon_intensity,
                                                    },
                                                ].concat(alternativeFuels.map(function (af, i) { return ({
                                                    name: af.description,
                                                    color: getComparisonColor(i + 1),
                                                    value: calculation.comparisons[i].carbon_intensity,
                                                }); })),
                                            },
                                        ] }) }), _jsx("div", { children: every(calculation.comparisons, function (comparison) {
                                        var _a, _b, _c, _d;
                                        return ((_d = (_c = (_b = (_a = comparison.maximum_price_to_be_cheaper) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.timeline) === null || _c === void 0 ? void 0 : _c.find(function (year) { return year.year === 2025; })) === null || _d === void 0 ? void 0 : _d.value) !== undefined;
                                    }) ? (_jsx(CompareBarChart, { unit: "$", details: "Ceiling price before fuel gets more expensive than the other alternative.", hideDifference: true, valueSeries: [
                                            {
                                                title: "Ceiling comparison price 2025, including EU ETS costs",
                                                selectorLabel: "incl. EU ETS impact",
                                                values: alternativeFuels.map(function (af, i) {
                                                    var _a, _b, _c, _d;
                                                    return ({
                                                        name: af.description,
                                                        color: getComparisonColor(i + 1),
                                                        value: (_d = (_c = (_b = (_a = calculation.comparisons[i].maximum_price_to_be_cheaper) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.timeline.find(function (d) { return d.year === 2025; })) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0,
                                                    });
                                                }),
                                            },
                                            {
                                                title: "Ceiling comparison price 2025, including EU ETS costs",
                                                selectorLabel: "Fuel cost premium only",
                                                values: alternativeFuels.map(function (af, i) {
                                                    var _a, _b, _c, _d;
                                                    return ({
                                                        name: af.description,
                                                        color: getComparisonColor(i + 1),
                                                        value: (_d = (_c = (_b = (_a = calculation.comparisons[i].maximum_price_to_be_cheaper_without_ets) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.timeline.find(function (d) { return d.year === 2025; })) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0,
                                                    });
                                                }),
                                            },
                                        ] })) : (_jsx(CompareBarChart, { unit: "% of 2025 target", valueSeries: [
                                            {
                                                title: "Carbon intensity",
                                                selectorLabel: "Carbon intensity",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: (originalFuel.intensity /
                                                            metadata.intensity[2025]) *
                                                            100,
                                                    },
                                                ].concat(alternativeFuels.map(function (af, i) {
                                                    var _a;
                                                    return ({
                                                        name: af.description,
                                                        color: getComparisonColor(i + 1),
                                                        value: ((((_a = params.comparison_fuels[i]) === null || _a === void 0 ? void 0 : _a.intensity) ||
                                                            af.average_intensity) /
                                                            metadata.intensity[2025]) *
                                                            100,
                                                    });
                                                })),
                                            },
                                        ] })) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "$/t", details: "Cost of staying compliant in FuelEU Maritime regulation.\n                  For the fossil fuel, this is the penalty cost, and for the alternatives, this\n                  is the energy cost premium with penalties, if any. Only fuels that comply with 2025 regulation are shown.", valueSeries: [
                                            {
                                                title: "Cost per reduced CO₂e ton 2025, incl. EU ETS costs and penalties",
                                                selectorLabel: "incl. EU ETS impact",
                                                values: [cost_per_exceeded_emission_ton].concat(calculation.comparisons
                                                    .filter(function (comparisonData) {
                                                    return comparisonData.alternative_fuel.intensity <
                                                        metadata.intensity[2025];
                                                })
                                                    .map(function (comparisonData, i) {
                                                    var _a, _b;
                                                    return ({
                                                        name: "".concat(alternativeFuels[i].description),
                                                        color: getComparisonColor(i + 1),
                                                        value: (_b = (_a = comparisonData.cost_per_reduced_emission_ton
                                                            .find(function (v) {
                                                            return !v.alternative &&
                                                                v.with_emissions &&
                                                                !v.with_engine &&
                                                                !v.with_penalty;
                                                        })) === null || _a === void 0 ? void 0 : _a.timeline.find(function (v) { return v.year === 2025; })) === null || _b === void 0 ? void 0 : _b.value,
                                                    });
                                                })
                                                    .filter(function (d) { return d.value !== undefined && d.value >= 0; })),
                                            },
                                            {
                                                title: "Cost per reduced CO₂e ton 2025, with penalties",
                                                selectorLabel: "Fuel cost premium only",
                                                values: [cost_per_exceeded_emission_ton].concat(calculation.comparisons
                                                    .filter(function (comparisonData) {
                                                    return comparisonData.alternative_fuel.intensity <
                                                        metadata.intensity[2025];
                                                })
                                                    .map(function (comparisonData, i) {
                                                    var _a, _b;
                                                    return ({
                                                        name: "".concat(alternativeFuels[i].description),
                                                        color: getComparisonColor(i + 1),
                                                        value: (_b = (_a = comparisonData.cost_per_reduced_emission_ton
                                                            .find(function (v) {
                                                            return !v.alternative &&
                                                                !v.with_emissions &&
                                                                !v.with_engine &&
                                                                !v.with_penalty;
                                                        })) === null || _a === void 0 ? void 0 : _a.timeline.find(function (v) { return v.year === 2025; })) === null || _b === void 0 ? void 0 : _b.value,
                                                    });
                                                })
                                                    .filter(function (d) { return d.value !== undefined && d.value >= 0; })),
                                            },
                                        ], hideDifference: true }) })] })), _jsxs(Legend, { children: [_jsx(LegendItem, { color: theme.colors.grey(2), text: originalFuel.fuel_name }), alternativeFuels.map(function (f, i) { return (_jsx(LegendItem, { color: getComparisonColor(i + 1), text: f.description }, f.description)); })] })] }), _jsxs(UISection, { children: [_jsx(TimelineChart, { title: "Fuel EU Maritime carbon intensity targets", subTitle: "The regulated carbon intensity target timeline, along with your energy sources' carbon intensities." // {texts.carbon_intensity_targets}
                            , unit: "gCO\u2082e/MJ", forceZero: true, forceDigits: 1, data: __spreadArray([
                                {
                                    name: "".concat(originalFuel.fuel_name, " intensity"),
                                    color: theme.colors.grey(2),
                                    values: Object.keys(metadata.intensity).map(function (year) { return ({
                                        year: Number(year),
                                        value: calculation.carbon_intensity,
                                    }); }),
                                    hideCircles: true,
                                },
                                {
                                    name: texts.target_for_each_year,
                                    color: theme.colors.blue,
                                    values: Object.keys(metadata.intensity).map(function (year) { return ({
                                        year: Number(year),
                                        value: metadata.intensity[year],
                                    }); }),
                                }
                            ], calculation.comparisons.map(function (c, i) { return ({
                                name: "".concat(c.fuel_name, " intensity"),
                                color: theme.colors.greenScale[i],
                                values: Object.keys(metadata.intensity).map(function (year) { return ({
                                    year: Number(year),
                                    value: c.carbon_intensity,
                                }); }),
                                hideCircles: true,
                            }); }), true), chartType: "stepAfter" }), _jsx(TimelineChart, { title: texts.penalty_title ||
                                "Yearly emission penalties with current energy source", subTitle: "Yearly penalties just using ".concat(originalFuel.fuel_name, ", converted to $ from European Central Bank's daily courses"), unit: "$", data: [
                                {
                                    name: originalFuel.fuel_name,
                                    color: theme.colors.grey(2),
                                    values: calculation.penalty,
                                },
                            ], canDivide: true, forceZero: true, chartType: "stepAfter" }), _jsxs(SectionTitle, { children: ["Energy mix plan", hasMultipleComparisons ? "s" : ""] }), _jsxs(P, { children: ["This is how the regulation affects the optimal energy source mix in each five-year period starting 2025.", fuelMixTimelines.length > 1
                                    ? " A separate plan is made for each alternative energy source."
                                    : ""] }), calculation.comparisons.length && (_jsx(Grid, __assign({ minCellWidth: minTimelineCellWidth }, { children: calculation.comparisons.map(function (comparison, index) {
                                var _a;
                                var comparisonFuel = alternativeFuels[index];
                                return comparisonFuel && comparison.mixes.length ? (_jsxs("div", { children: [_jsxs(GraphTitle, { children: ["Energy source mix with ", comparisonFuel.description] }), _jsx(TimelineChart, { subTitle: "Amount of each fuel needed to meet the carbon intensity targets", unit: texts.weight_unit, forceZero: true, canDivide: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.comparisons[index], data: [
                                                {
                                                    name: "FOC, ".concat(originalFuel.fuel_name),
                                                    color: theme.colors.grey(2),
                                                    values: comparison.mixes.map(function (mixYear) {
                                                        return {
                                                            year: mixYear.year,
                                                            value: mixYear.original_fuel.foc,
                                                        };
                                                    }),
                                                },
                                                {
                                                    name: "FOC, ".concat(comparisonFuel.description),
                                                    color: theme.colors.green,
                                                    values: comparison.mixes.map(function (m) {
                                                        return {
                                                            year: m.year,
                                                            value: m.alternative_fuel.foc,
                                                        };
                                                    }),
                                                },
                                            ], chartType: "stepAfter" }), _jsx(TimelineChart, { subTitle: texts.energy_source_mix_percentage, unit: "%", forceZero: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.comparisons[index], data: [
                                                {
                                                    name: "Weight percentage of ".concat(originalFuel.fuel_name),
                                                    color: theme.colors.grey(2),
                                                    values: comparison.mixes.map(function (m) {
                                                        return {
                                                            year: m.year,
                                                            value: m.original_fuel.weight_percentage,
                                                        };
                                                    }),
                                                },
                                                {
                                                    name: "Weight percentage of ".concat(comparisonFuel.description),
                                                    color: theme.colors.green,
                                                    values: comparison.mixes.map(function (m) {
                                                        return {
                                                            year: m.year,
                                                            value: m.alternative_fuel.weight_percentage,
                                                        };
                                                    }),
                                                },
                                            ], chartType: "stepAfter" }), _jsx(PrivateContentVeil, __assign({ title: texts.fleet_mix_title || "Fleet mix", subTitle: "From the energy source mix, we can generate a plan for how many ships using each energy source would be needed to stay compliant." }, { children: _jsx(FleetMixChart, { title: texts.fleet_mix_title || "Fleet mix", subTitle: "From the energy source mix, we can generate a plan for how many ships using each energy source would be needed to stay compliant." // {texts.fleet_mix}
                                                , unit: texts.fleet_mix_unit || "Ships", fuels: [
                                                    {
                                                        fuel: originalFuel.fuel,
                                                        description: originalFuel.fuel_name,
                                                        intensity_text: getCarbonIntensity(originalFuel.intensity),
                                                    },
                                                    __assign(__assign({}, comparisonFuel), { intensity_text: (_a = comparisonFuel.intensity_text) !== null && _a !== void 0 ? _a : getCarbonIntensity(comparisonFuel.average_intensity) }),
                                                ], fleetSize: params.fleet_size, forceZero: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.comparisons[index], data: [
                                                    {
                                                        name: "Ships using ".concat(originalFuel.fuel_name),
                                                        color: theme.colors.grey(2),
                                                        values: comparison.mixes.map(function (m) {
                                                            return {
                                                                year: m.year,
                                                                value: m.original_fuel.number_of_ships,
                                                            };
                                                        }),
                                                    },
                                                    {
                                                        name: "Ships using ".concat(comparisonFuel.description),
                                                        color: theme.colors.green,
                                                        values: comparison.mixes.map(function (m) {
                                                            return {
                                                                year: m.year,
                                                                value: m.alternative_fuel.number_of_ships,
                                                            };
                                                        }),
                                                    },
                                                ], chartType: "bar" }) }))] }, comparisonFuel.fuel)) : (_jsx(P, { children: texts.no_fuel_mix }));
                            }) })))] }), _jsxs(UISection, { children: [_jsxs(SectionTitle, { children: ["Original path vs. fuel mix path", alternativeFuels.length > 1 ? "s" : ""] }), _jsxs(P, { children: ["These graphs compare the total cost and emissions of using the original fuel versus migrating to the new fuel", alternativeFuels.length > 1 ? "s" : "", " according to our fuel mix plan, from the year 2025 to ", lastAccumulationYear, ", taking into account emission costs and penalties.", lastAccumulationYear !== 2050
                                    ? " Note that the current plan is not compliant into the year 2050"
                                    : ""] }), _jsx(PrivateContentVeil, __assign({ title: "Total CO\u2082e emissions and total costs 2025-".concat(lastAccumulationYear) }, { children: _jsxs(Grid, { children: [_jsx("div", { children: cumulativeEmissions.fuelMixes !== undefined && (_jsx(CompareBarChart, { unit: texts.weight_unit, valueSeries: [
                                                {
                                                    title: "Total CO\u2082e emissions 2025-".concat(lastAccumulationYear),
                                                    selectorLabel: "Total CO\u2082e emissions 2025-".concat(lastAccumulationYear),
                                                    values: [
                                                        {
                                                            name: "Original",
                                                            value: cumulativeEmissions.original,
                                                            color: getComparisonColor(0),
                                                        },
                                                    ].concat(cumulativeEmissions.fuelMixes.map(function (mix, i) { return ({
                                                        name: "Fuel mix, ".concat(alternativeFuels[i].description),
                                                        value: mix,
                                                        color: getComparisonColor(i + 1),
                                                    }); })),
                                                },
                                            ] })) }), _jsx("div", { children: cumulativeCosts.fuelMixes !== undefined && (_jsx(CompareBarChart, { unit: "M$", valueSeries: [
                                                {
                                                    title: "Total cost 2025-".concat(lastAccumulationYear),
                                                    selectorLabel: "Total cost 2025-".concat(lastAccumulationYear),
                                                    values: [
                                                        {
                                                            name: "Original",
                                                            value: cumulativeCosts.original / 1000000,
                                                            color: getComparisonColor(0),
                                                        },
                                                    ].concat(cumulativeCosts.fuelMixes.map(function (mix, i) { return ({
                                                        name: "Fuel mix, ".concat(alternativeFuels[i].description),
                                                        value: mix / 1000000,
                                                        color: getComparisonColor(i + 1),
                                                    }); })),
                                                },
                                            ] })) })] }) })), _jsxs(Legend, { children: [_jsx(LegendItem, { color: theme.colors.grey(2), text: originalFuel.fuel_name }), alternativeFuels.map(function (f, i) { return (_jsx(LegendItem, { color: getComparisonColor(i + 1), text: "Fuel mix plan towards " + f.description }, f.description)); })] }), (cumulativeCosts === null || cumulativeCosts === void 0 ? void 0 : cumulativeCosts.fuelMixes.length) > 0 &&
                            fuelMixTimelines.filter(function (fuelMix) {
                                return fuelMix.every(function (y) { return y !== undefined; });
                            }).length > 0 && (_jsxs(_Fragment, { children: [_jsx(PrivateContentVeil, __assign({ title: "Yearly cost of fossil path versus fuel mix path", subTitle: "Comparison of fossil path costs (fuel cost, emission cost and emission penalties) to fuel mix costs (engine change costs, cost of fuels, and emission costs). Engine change cost represents the CAPEX needed to enable the use of the selected alternative fuel. It is a rough premium of a ship with a possibility to use the selected alternative fuel compared to a similar size, conventional fuel vessel. The cost is allocated over the vessel's life-cycle period of 20 years." }, { children: _jsx(TimelineChart, { title: "Yearly cost of fossil path versus fuel mix path", subTitle: "Comparison of fossil path costs (fuel cost, emission cost and emission penalties) to fuel mix costs (engine change costs, cost of fuels, and emission costs). Engine change cost represents the CAPEX needed to enable the use of the selected alternative fuel. It is a rough premium of a ship with a possibility to use the selected alternative fuel compared to a similar size, conventional fuel vessel. The cost is allocated over the vessel's life-cycle period of 20 years.", unit: "$", canDivide: true, forceZero: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: [
                                            {
                                                name: "Cost of plan with ".concat(originalFuel.fuel_name, " only"),
                                                color: theme.colors.grey(2),
                                                values: originalPathTimeline,
                                            },
                                        ].concat(fuelMixTimelines.map(function (fm, i) {
                                            var _a;
                                            return ({
                                                name: "Yearly cost of migrating from ".concat(originalFuel.fuel_name, " to ").concat(alternativeFuels[i].description),
                                                color: (_a = theme.colors.greenScale[i]) !== null && _a !== void 0 ? _a : "black",
                                                values: fm.filter(function (v) { return v; }),
                                            });
                                        })), chartType: "stepAfter" }) })), _jsx(PrivateContentVeil, __assign({ title: "Cost breakdown of original path (".concat(originalFuel.fuel_name, ")") }, { children: _jsx(TimelineChart, { subTitle: "Cost breakdown of original path (".concat(originalFuel.fuel_name, ")"), unit: "$", canDivide: true, forceZero: true, 
                                        // forcedMaxValue={maxCost} don't force same scale as below
                                        errorYearMessage: texts.no_compliance, data: [
                                            {
                                                name: "Fuel cost",
                                                color: theme.colors.grey(2),
                                                values: originalPathTimeline.map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.fuel_cost,
                                                }); }),
                                            },
                                            {
                                                name: "Emission cost",
                                                color: theme.colors.grey(1),
                                                values: originalPathTimeline.map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.emission_cost,
                                                }); }),
                                            },
                                            {
                                                name: "Penalties",
                                                color: theme.colors.grey(0),
                                                values: originalPathTimeline.map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.penalty,
                                                }); }),
                                            },
                                        ], chartType: "bar" }) })), _jsx(PrivateContentVeil, __assign({ title: "Cost breakdowns of fuel mix plans towards alternative fuels" }, { children: _jsx(Grid, __assign({ minCellWidth: minTimelineCellWidth }, { children: fuelMixTimelines.map(function (fm, i) { return (_jsx("div", { children: _jsx(TimelineChart, { subTitle: "Cost breakdown of fuel mix plan towards ".concat(alternativeFuels[i].description), unit: "$", canDivide: true, forceZero: true, 
                                                // forcedMaxValue={maxCost}
                                                errorYearMessage: texts.no_compliance, complianceData: complianceData.comparisons[i], data: [
                                                    {
                                                        name: "Fuel cost",
                                                        color: theme.colors.lightGreen,
                                                        values: fm
                                                            .filter(function (v) { return v; })
                                                            .map(function (v) { return ({
                                                            year: v.year,
                                                            value: v.fuel_cost,
                                                        }); }),
                                                    },
                                                    {
                                                        name: "Emission cost",
                                                        color: theme.colors.green,
                                                        values: fm
                                                            .filter(function (v) { return v; })
                                                            .map(function (v) { return ({
                                                            year: v.year,
                                                            value: v.emission_cost,
                                                        }); }),
                                                    },
                                                    {
                                                        name: "Penalties",
                                                        color: theme.colors.darkGreen,
                                                        values: fm
                                                            .filter(function (v) { return v; })
                                                            .map(function (v) { return ({
                                                            year: v.year,
                                                            value: v.penalty,
                                                        }); }),
                                                    },
                                                    {
                                                        name: "Engine change cost",
                                                        color: theme.colors.darkestGreen,
                                                        values: fm
                                                            .filter(function (v) { return v; })
                                                            .map(function (v) { return ({
                                                            year: v.year,
                                                            value: v
                                                                .annual_technology_cost_for_alternative_fuel_ships,
                                                        }); }),
                                                    },
                                                ], chartType: "bar" }, alternativeFuels[i].description) }, alternativeFuels[i].description)); }) })) }))] }))] }), _jsxs(UISection, { children: [calculation.comparisons.length && (_jsx(PrivateContentVeil, __assign({ title: "Yearly emissions of different plans" }, { children: _jsx(Grid, __assign({ minCellWidth: minTimelineCellWidth }, { children: calculation.comparisons.map(function (comparisonData, i) { return (_jsxs("div", { children: [_jsx(TimelineChart, { title: "".concat(texts.emissions_and_allowance_title).concat(hasMultipleComparisons
                                                ? ", ".concat(alternativeFuels[i].description)
                                                : ""), subTitle: "The fuel mix plan is designed to follow the emission allowance limit.", unit: "tCO\u2082e/y", forceZero: true, canDivide: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.comparisons[i], data: [
                                                {
                                                    name: texts.emissions_and_allowance_item,
                                                    color: theme.colors.blue,
                                                    values: comparisonData.allowed_co2_emissions,
                                                },
                                                {
                                                    name: originalFuel.fuel_name,
                                                    color: theme.colors.grey(2),
                                                    values: comparisonData.allowed_co2_emissions.map(function (d) { return (__assign(__assign({}, d), { value: calculation.co2_equivalent_emissions })); }),
                                                },
                                                {
                                                    name: "Fuel mix plan with ".concat(alternativeFuels[i].description),
                                                    color: theme.colors.green,
                                                    values: getYearValues(comparisonData.mixes, "fuel_mix_co2_equivalent_emissions"),
                                                },
                                            ], chartType: "stepAfter" }), some(getYearValues(comparisonData.mixes, "total_penalty_costs"), function (c) { return c.value > 0; }) && (_jsx(TimelineChart, { title: "Penalties with fuel mixes", subTitle: "Some of the years in the fuel mix are not compliant with regulation. Here are the penalties accumulated for the energy sources with the plan. Note that the penalties come from the new energy source, because the transition has been made at that point.", unit: "$", canDivide: true, forceZero: true, chartType: "stepAfter", errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: [
                                                {
                                                    name: "Penalties",
                                                    color: theme.colors.grey(2),
                                                    values: getYearValues(comparisonData.mixes, "total_penalty_costs"),
                                                },
                                            ] }))] }, alternativeFuels[i].fuel)); }) })) }))), _jsx(PrivateContentVeil, __assign({ title: "Annual emission reduction" }, { children: _jsx(TimelineChart, { title: "Annual emission reduction", unit: "tCO\u2082e/y", canDivide: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: calculation.comparisons.map(function (comparisonData, i) { return ({
                                    name: "Fuel mix plan with ".concat(alternativeFuels[i].description),
                                    color: theme.colors.greenScale[i],
                                    values: getYearValues(comparisonData.mixes, "co2e_reduction"),
                                }); }), chartType: "bar" }) }))] })] })) }));
});
