var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { Bold, PageContent, PTight, Row, SectionTitle, SeparatedRow, SmallSubtitle, WideTextContent, } from "../../common-styled-components";
import useFetch from "../../../hooks/useFetch";
import { LoaderStatus } from "../../../util/apiUtils";
import ComplianceKPI from "../common/ComplianceKPI";
import { getCompliance } from "../../../util/compliance/compliance";
import { useMetadata } from "../../../hooks/useMetadata";
import { scaleEmissions } from "../util";
import { theme } from "../../../theme";
import { log } from "../../../util/log";
import { defaultYear } from "../../../util/constants";
import { some, values } from "lodash";
import { formatYearMonth } from "../../../util/timeUtils";
import useFetchAll from "../../../hooks/useFetchAll";
import { useEffect } from "react";
import { MonthlyReportTable } from "../common/tables/MonthlyReportTable";
import { TransactionTable } from "../common/tables/TransactionTable";
var enrichShipData = function (monthlyReports, ships) {
    return monthlyReports.filter(function (d) { return d; }).map(function (report) {
        var ship = ships.find(function (s) { return s.id === (report === null || report === void 0 ? void 0 : report.ship_id); });
        return __assign(__assign({}, report), { additionalInfo: ship
                ? {
                    shipName: ship.name,
                }
                : undefined });
    });
};
export default (function () {
    var _a = useParams(), companyId = _a.companyId, poolId = _a.poolId, year = _a.year, month = _a.month;
    var metadata = useMetadata()[0];
    var params = {
        company_id: Number(companyId),
        pool_id: Number(poolId),
        year: Number(year),
        month: Number(month),
    };
    if (some(values(params), function (v) { return isNaN(v); })) {
        return (_jsx(PageContent, { children: "Faulty search params, please return to previous page and try again." }));
    }
    var date = new Date(params.year, params.month - 1, 1);
    var monthly_report = useFetch("admin/monthly_data.json", JSON.stringify(params))[0];
    var companies = useFetch("admin/companies.json")[0];
    var pools = useFetch("admin/pools.json", JSON.stringify({
        pool_id: params.pool_id,
        year: params.year,
    }))[0];
    var ships = useFetch("admin/ships.json", JSON.stringify({
        company_id: params.company_id,
        pool_id: params.pool_id,
    }))[0];
    var transactions = useFetch("admin/transactions.json", JSON.stringify({
        pool_id: Number(poolId),
        year: defaultYear,
    }))[0];
    var _b = useFetchAll("admin/monthly_data.json", {
        year: defaultYear,
        month: params.month,
        company_id: params.company_id,
        pool_id: params.pool_id,
    }), ship_reports = _b[0], _setMonthlyReportsUrl = _b[1], _setMonthlyReportsBody = _b[2], setMonthlyReportsVariations = _b[3];
    useEffect(function () {
        if (ships.status === LoaderStatus.Success && ships.data.length) {
            setMonthlyReportsVariations({
                key: "ship_id",
                variations: ships.data.map(function (s) { return s.id; }),
            });
        }
    }, [ships.status]);
    if (monthly_report.status !== LoaderStatus.Success ||
        companies.status !== LoaderStatus.Success ||
        pools.status !== LoaderStatus.Success ||
        ships.status !== LoaderStatus.Success ||
        ship_reports.status !== LoaderStatus.Success ||
        transactions.status !== LoaderStatus.Success ||
        !metadata) {
        return _jsx(PageContent, { children: "Loading\u2026" });
    }
    var company = companies.data.find(function (c) { return c.id === params.company_id; });
    var pool = pools.data.find(function (p) { return p.id === params.pool_id; });
    var monthlyReport = monthly_report.data;
    // find transactions regarding this company's ships and in this month
    var transactionsData = transactions.data
        .filter(function (d) {
        return ships.data.find(function (s) { return d.from.id === s.id || d.to.id === s.id; });
    })
        .filter(function (d) {
        var transactionDate = new Date(d.transaction_date);
        return (transactionDate.getFullYear() === params.year &&
            transactionDate.getMonth() + 1 === params.month);
    });
    log([
        {
            label: "Report data",
            content: monthly_report.data,
        },
        {
            label: "Ship reports",
            content: ship_reports.data,
        },
    ]);
    var compliance = getCompliance(metadata, defaultYear, monthlyReport.intensity.adjusted_intensity_gCO2e_per_MJ);
    var enrichedShipReports = enrichShipData(ship_reports.data, ships.data);
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsxs(SectionTitle, { children: ["Report for ", formatYearMonth(date)] }), _jsxs(SeparatedRow, { children: [_jsxs("div", __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsxs(PTight, { children: [_jsx(Bold, { children: "Company: " }), company === null || company === void 0 ? void 0 : company.name] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "Pool: " }), pool === null || pool === void 0 ? void 0 : pool.name] })] })), _jsxs(Row, { children: [_jsx(ComplianceKPI, { compliance: compliance, title: "Carbon intensity", unit: "gCO\u2082e/MJ", value: monthlyReport.intensity.adjusted_intensity_gCO2e_per_MJ }), _jsx(ComplianceKPI, { compliance: compliance, title: "Carbon unit balance", unit: monthlyReport.intensity.adjusted_emission_balance_ton < 0
                                    ? "tCO₂e deficit"
                                    : "tCO₂e to spare", value: scaleEmissions(monthlyReport.intensity.adjusted_emission_balance_ton) })] })] }), _jsxs(WideTextContent, { children: [_jsx(SmallSubtitle, __assign({ style: {
                            marginBottom: theme.spacing(2),
                            marginTop: theme.spacing(5),
                        } }, { children: "Ships" })), _jsx(MonthlyReportTable, { monthlyReports: enrichedShipReports, metadata: metadata, complianceYear: defaultYear, showAdditionalInfo: ["shipName"] }), _jsx(SmallSubtitle, __assign({ style: {
                            marginBottom: theme.spacing(2),
                            marginTop: theme.spacing(4),
                        } }, { children: "Transactions" })), _jsx(TransactionTable, { complianceYear: defaultYear, transactions: transactionsData, poolId: pool === null || pool === void 0 ? void 0 : pool.id })] })] })));
});
