var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useFetch from "../../../hooks/useFetch";
import { BackLink, Bold, PageContent, PTight, Row, SectionTitle, SeparatedRow, } from "../../common-styled-components";
import { log } from "../../../util/log";
import { LoaderStatus } from "../../../util/apiUtils";
import { theme } from "../../../theme";
import { PoolTable } from "../common/tables/PoolTable";
import { useMetadata } from "../../../hooks/useMetadata";
import { FleetTable } from "../common/tables/FleetTable";
import { ShipTable } from "../common/tables/ShipTable";
import NumberKPI from "../common/NumberKPI";
import { routeNames } from "../../../util/routes";
import { defaultYear } from "../../../util/constants";
import { useParams } from "react-router-dom";
import useFetchAll from "../../../hooks/useFetchAll";
import { useEffect } from "react";
import { CompanyYearlyDataTable } from "../common/tables/CompanyYearlyPlanTable";
export default (function () {
    var companyId = useParams().companyId;
    var _a = useFetch("admin/companies.json"), companies = _a[0], _setCUrl = _a[1], _setCBody = _a[2], refreshCompanyData = _a[3];
    var companyIdNumber = Number(companyId);
    var company = companies.status === LoaderStatus.Success
        ? companies.data.find(function (c) { return c.id === companyIdNumber; })
        : undefined;
    var pools = useFetch("admin/pools.json", JSON.stringify({ company_id: companyIdNumber, year: defaultYear }))[0];
    var fleets = useFetch("admin/fleets.json", JSON.stringify({ company_id: companyIdNumber, year: defaultYear }))[0];
    var ships = useFetch("admin/ships.json", JSON.stringify({ company_id: companyIdNumber, year: defaultYear }))[0];
    var _b = useFetchAll("admin/monthly_data.json", {
        year: defaultYear,
        company_id: companyIdNumber,
    }), monthly_reports = _b[0], _setMonthlyReportsUrl = _b[1], _setMonthlyReportsBody = _b[2], setMonthlyReportsVariations = _b[3];
    useEffect(function () {
        if (pools.status === LoaderStatus.Success && pools.data.length) {
            setMonthlyReportsVariations({
                key: "pool_id",
                variations: pools.data.map(function (p) { return p.id; }),
            });
        }
    }, [pools.status]);
    var metadata = useMetadata()[0];
    log([
        {
            label: "Company",
            content: company,
        },
        {
            label: "Pools",
            content: pools.status === LoaderStatus.Success ? pools.data : "loading",
        },
        {
            label: "Monthly reports",
            content: monthly_reports,
        },
        // {
        //   label: "Fleets",
        //   content: fleets.status === LoaderStatus.Success ? fleets.data : "loading",
        // },
        // {
        //   label: "Ships",
        //   content: ships.status === LoaderStatus.Success ? ships.data : "loading",
        // },
    ]);
    if (companies.status === LoaderStatus.Error ||
        fleets.status === LoaderStatus.Error ||
        pools.status === LoaderStatus.Error ||
        ships.status === LoaderStatus.Error) {
        return (_jsx(PageContent, __assign({ align: "left" }, { children: "Could not load data, please try again." })));
    }
    if (companies.status !== LoaderStatus.Success ||
        company === undefined ||
        fleets.status !== LoaderStatus.Success ||
        !fleets.data ||
        pools.status !== LoaderStatus.Success ||
        !pools.data ||
        ships.status !== LoaderStatus.Success ||
        !ships.data ||
        !metadata) {
        return _jsx(PageContent, __assign({ align: "left" }, { children: "Loading\u2026" }));
    }
    var nonCompliantShips = ships.data.filter(function (s) { return s.intensity.adjusted_emission_balance_ton < 0; }).length;
    var nonCompliantPools = pools.data.filter(function (p) { return p.intensity.adjusted_emission_balance_ton < 0; }).length;
    return (_jsx("div", __assign({ style: { width: "100%", padding: theme.spacing(5) } }, { children: _jsxs(PageContent, __assign({ align: "left", style: { gap: theme.spacing(5) } }, { children: [_jsx(BackLink, __assign({ to: routeNames.main }, { children: "\u2039 All tools" })), _jsx(SectionTitle, { children: company.name }), _jsxs(SeparatedRow, { children: [_jsxs("div", __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsxs(PTight, { children: [_jsx(Bold, { children: "Ships: " }), ships.data.length] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "Pools: " }), pools.data.length] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "Fleets: " }), fleets.data.length] })] })), _jsxs(Row, { children: [_jsx(NumberKPI, { title: "non-compliant ships", value: nonCompliantShips, color: nonCompliantShips > 0
                                        ? theme.colors.blackText
                                        : theme.colors.green }), _jsx(NumberKPI, { title: "non-compliant pools", value: nonCompliantPools, color: nonCompliantPools > 0
                                        ? theme.colors.blackText
                                        : theme.colors.green })] })] }), _jsx(CompanyYearlyDataTable, { company: company, metadata: metadata, isInAdminTools: true, refresh: refreshCompanyData }), _jsx(PoolTable, { pools: pools.data, isInAdminTools: true, metadata: metadata }), _jsx(FleetTable, { fleets: fleets.data, isInAdminTools: true, metadata: metadata }), _jsx(ShipTable, { ships: ships.data, isInAdminTools: true, metadata: metadata, complianceYear: defaultYear })] })) })));
});
