var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, PageContent, SmallButton, TD, TH, TR, Table, TitleWithExtraContent, } from "../../common-styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "../../../hooks/useFetch";
import { getAuthHeader } from "../../../util/auth";
import useEdit from "../../../hooks/useEdit";
import { Modal } from "./Modal";
import { LoaderStatus } from "../../../util/apiUtils";
import { routeNames } from "../../../util/routes";
import { Link } from "react-router-dom";
var tableColumns = [
    {
        key: "name",
        label: "Name",
    },
    {
        key: "ships",
        label: "Ships",
    },
    // {
    //   key: "currentIntensity",
    //   label: "Intensity",
    // },
    // {
    //   key: "currentTotalCO2eEmissionTonnes",
    //   label: "Emissions (tCO2e)",
    // },
    {
        key: "tools",
        label: "Tools",
    },
];
var CellContent = function (_a) {
    var pool = _a.pool, contentKey = _a.contentKey, editPool = _a.editPool, removePool = _a.removePool;
    switch (contentKey) {
        case "name":
            return (_jsx(Link, __assign({ to: "".concat(routeNames.admin, "/").concat(routeNames.poolAdmin, "/").concat(pool.id) }, { children: pool.name })));
        case "ships":
            return _jsx("div", { children: pool.ships.length });
        case "tools":
            return (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removePool(pool); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editPool(pool); } }, { children: "Edit" }))] }));
        // case "reports":
        //   return <div>{pool.reports?.length ?? ""}</div>;
        default:
            return _jsx("div", { children: pool[contentKey] });
    }
};
export default (function () {
    var user = useAuth0().user;
    var _a = useFetch("admin/pools.json", JSON.stringify({}), __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), pools = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    var _b = useEdit("admin/pool.json", __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }), refresh), editStatus = _b[0], executeEdit = _b[1];
    var _c = useState({}), modal = _c[0], setModal = _c[1];
    var addPool = function () {
        setModal({
            type: "add",
            value: undefined,
        });
    };
    var editPool = function (pool) {
        setModal({
            type: "edit",
            value: pool,
        });
    };
    var removePool = function (pool) {
        setModal({
            type: "remove",
            value: pool,
        });
    };
    var submit = function (type, value) {
        var _a;
        setModal({});
        if (type === "add") {
            if (value.name && value.name !== "") {
                var data = {
                    name: value.name,
                    id: null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "edit") {
            if (value.name && value.name !== "") {
                var data = {
                    name: value.name,
                    id: (_a = value.id) !== null && _a !== void 0 ? _a : null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(Modal, { type: "pool", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit }), _jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(Button, __assign({ onClick: addPool }, { children: "+ Add" })) }, { children: "Pools" })), _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: tableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: pools.status === LoaderStatus.Success &&
                            pools.data.map(function (pool) { return (_jsx(TR, { children: tableColumns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, pool: pool, removePool: removePool, editPool: editPool }) }, c.key)); }) }, pool.id)); }) })] })] })));
});
