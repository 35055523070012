export var getSVGPoint = function (clientX, clientY, svgRef) {
    var _a;
    if (svgRef.current !== null) {
        var pt = svgRef.current.createSVGPoint();
        pt.x = clientX;
        pt.y = clientY;
        var svgPoint = pt.matrixTransform((_a = svgRef.current.getScreenCTM()) === null || _a === void 0 ? void 0 : _a.inverse());
        return svgPoint;
    }
    return undefined;
};
