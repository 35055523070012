var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { routeNames } from "../../../../util/routes";
import { ComplianceIcon, StrippedLink, Table, TD, TH, TR, } from "../../../common-styled-components";
import { complianceOptions, getCompliance, } from "../../../../util/compliance/compliance";
import { theme } from "../../../../theme";
import { formatNumber } from "../../../../util/amountUtils";
import { formatYearMonth } from "../../../../util/timeUtils";
var getAdditionalInfoColumn = function (infoName) {
    switch (infoName) {
        case "companyName":
            return {
                key: infoName,
                label: "Company",
            };
        case "poolName":
            return {
                key: infoName,
                label: "Pool",
            };
        case "shipName":
            return {
                key: infoName,
                label: "Ship",
            };
    }
};
var monthlyReportTableColumns = function (additionalInfo) {
    return (additionalInfo ? additionalInfo.map(getAdditionalInfoColumn) : []).concat([
        {
            key: "date",
            label: "Month",
        },
        {
            key: "pool_id",
            label: "Pool",
        },
        {
            key: "intensity",
            label: "Intensity (gCO₂e/MJ)",
        },
        {
            key: "balance",
            label: "+/- (tCO₂e)",
        },
    ]);
};
var CellContent = function (_a) {
    var _b;
    var monthlyReport = _a.monthlyReport, contentKey = _a.contentKey, compliance = _a.compliance;
    switch (contentKey) {
        case "date":
            var link = "".concat(routeNames.admin, "/").concat(routeNames.monthlyReportAdmin, "/").concat(monthlyReport.company_id);
            return (_jsx(StrippedLink, __assign({ to: link, style: { color: theme.colors.blue } }, { children: formatYearMonth(new Date(monthlyReport.year, monthlyReport.month - 1, 1)) })));
        case "intensity":
            if (monthlyReport.intensity) {
                return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [_jsx(ComplianceIcon, { src: complianceOptions[compliance].icon }), formatNumber(monthlyReport.intensity.adjusted_intensity_gCO2e_per_MJ, 2)] })));
            }
            else {
                return _jsx("div", {});
            }
        case "balance":
            if (monthlyReport.intensity) {
                return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [monthlyReport.intensity.adjusted_emission_balance_ton > 0
                            ? "+"
                            : "", formatNumber(monthlyReport.intensity.adjusted_emission_balance_ton, 0)] })));
            }
            else {
                return _jsx("div", {});
            }
        case "additionalInfo":
            return _jsx("div", {});
        case "poolName":
        case "companyName":
        case "shipName":
            return _jsx("div", { children: (_b = monthlyReport.additionalInfo) === null || _b === void 0 ? void 0 : _b[contentKey] });
        default:
            return _jsx("div", { children: monthlyReport[contentKey] });
    }
};
export var MonthlyReportTable = function (_a) {
    var monthlyReports = _a.monthlyReports, complianceYear = _a.complianceYear, metadata = _a.metadata, showAdditionalInfo = _a.showAdditionalInfo;
    var columns = monthlyReportTableColumns(showAdditionalInfo);
    return (metadata && (_jsx("div", { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: columns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: monthlyReports.map(function (monthlyReport) {
                        var compliance = monthlyReport.intensity &&
                            getCompliance(metadata, complianceYear, monthlyReport.intensity.adjusted_intensity_gCO2e_per_MJ);
                        return (_jsx(TR, { children: columns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, monthlyReport: monthlyReport, compliance: compliance }) }, c.key)); }) }, "".concat(monthlyReport.company_id, " ").concat(monthlyReport.pool_id, " ").concat(monthlyReport.year, " ").concat(monthlyReport.month)));
                    }) })] }) })));
};
