var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { theme } from "../../theme";
import intensityLow from "../../../images/intensity-low.png";
import intensityMedium from "../../../images/intensity-medium.png";
import intensityHigh from "../../../images/intensity-high.png";
import styled from "styled-components";
import Select from "react-select";
import { Selector, SelectorLabel } from "../common-styled-components";
import { getCarbonIntensity } from "../../util/carbonIntensity";
import { isAlternativeFuelParams, } from "../fleet-calculator/calculator-types";
import { NumberInput } from "../generic/NumberInput";
export var getFuelOptions = function (fuels, isComparison) {
    return (Object.keys(fuels)
        .map(function (key) {
        var _a;
        return ({
            value: key,
            label: fuels[key].name,
            intensityText: (_a = fuels[key].intensity_text) !== null && _a !== void 0 ? _a : getCarbonIntensity(fuels[key].average_intensity),
        });
    })
        // .filter((option) =>
        //   isComparison
        //     ? option.intensityText !== "high" || option.value === "8"
        //     : fuels[option.value].is_source_fuel
        // )
        .sort(function (f1, f2) { return f1.label.localeCompare(f2.label); }));
};
export var fuelSelectStyle = {
    control: function (baseStyles, _state) { return (__assign(__assign({}, baseStyles), { borderColor: theme.colors.grey(3) })); },
};
var IntensityImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 32px;\n  width: 32px;\n  margin-right: ", ";\n"], ["\n  height: 32px;\n  width: 32px;\n  margin-right: ", ";\n"])), theme.spacing(2));
var energyIcon = function (intensityText) {
    switch (intensityText) {
        case "low":
            return _jsx(IntensityImage, { src: intensityLow, width: 32 });
        case "medium":
            return _jsx(IntensityImage, { src: intensityMedium, width: 32 });
        case "high":
            return _jsx(IntensityImage, { src: intensityHigh, width: 32 });
    }
};
var OptionContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: 32px;\n"], ["\n  display: flex;\n  align-items: center;\n  height: 32px;\n"])));
export var fuelOptionLabel = function (_a) {
    var label = _a.label, intensityText = _a.intensityText;
    return (_jsxs(OptionContent, { children: [energyIcon(intensityText), _jsx("div", { children: label })] }));
};
export var FuelSelector = function (_a) {
    var isComparison = _a.isComparison, texts = _a.texts, metadata = _a.metadata, onChangeFuelType = _a.onChangeFuelType, params = _a.params, editable = _a.editable, setParamsHelper = _a.setParamsHelper;
    var fuelOptions = getFuelOptions(metadata.fuels, isComparison); // allow all
    return (_jsxs("div", { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: isComparison ? texts.fuel_type_for_comparison : texts.fuel_type }), _jsx(Select, { options: fuelOptions, onChange: function (newValue) {
                            return onChangeFuelType((newValue === null || newValue === void 0 ? void 0 : newValue.value) || "1", metadata);
                        }, value: params.fuel_type !== undefined
                            ? fuelOptions.find(function (f) { return f.value === params.fuel_type.toString(); })
                            : undefined, isOptionSelected: function (option) {
                            return params.fuel_type !== undefined
                                ? option.value === params.fuel_type.toString()
                                : false;
                        }, styles: fuelSelectStyle, formatOptionLabel: fuelOptionLabel }), params.fuel_type !== undefined && (_jsx(_Fragment, { children: _jsxs("p", __assign({ style: {
                                marginTop: theme.spacing(0),
                                marginBottom: 0,
                            } }, { children: ["LCV:", " ", metadata.fuels[params.fuel_type.toString()].energy_content.toLocaleString("en-US", {
                                    maximumFractionDigits: 3,
                                }), " ", "MJ/g"] })) }))] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: texts.price_per_ton }), _jsx(NumberInput, { value: params.fuel_price, onChange: function (value) { return setParamsHelper({ fuel_price: value }); } })] }), isComparison && isAlternativeFuelParams(params) && (_jsxs(Selector, { children: [_jsx(SelectorLabel, __assign({ disabled: !editable }, { children: "Engine change cost (USD)" })), _jsx(NumberInput, { disabled: !editable, value: params.engine_change_cost, onChange: function (value) {
                            return setParamsHelper({ engine_change_cost: value });
                        } })] }))] }));
};
var templateObject_1, templateObject_2;
