import { every, isNumber, isString } from "lodash";
import { isNullish } from "../../util/isNullish";
export var isShipEngineType = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        (typeof obj.id === "number" || obj.id === undefined) &&
        typeof obj.type === "number" &&
        typeof obj.ship_engine_type === "number");
};
var imoRegex = /^9\d{6}$/;
export var isValidIMO = function (imoString) {
    return imoRegex.test(imoString);
};
export var isValidShipInput = function (ship) {
    return (!isNullish(ship.name) &&
        ship.name !== "" &&
        !isNullish(ship.imo) &&
        isValidIMO(ship.imo) &&
        !isNullish(ship.fleet_id) &&
        !isNullish(ship.engines) &&
        ship.engines.length > 0 &&
        every(ship.engines, function (engine) { return isShipEngineType(engine); }));
};
export var isValidTransactionInput = function (transaction) {
    return (isNumber(transaction.from) &&
        isNumber(transaction.to) &&
        isNumber(transaction.alternative_cost) &&
        isNumber(transaction.eu_ets_benefit) &&
        isNumber(transaction.pool_id) &&
        isNumber(transaction.year) &&
        isString(transaction.transaction_date) &&
        isNumber(transaction.quantity) &&
        isNumber(transaction.total_to_be_paid) &&
        isNumber(transaction.total_to_be_received) &&
        isNumber(transaction.production_cost) &&
        isNumber(transaction.transaction_cost));
};
