var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import { theme } from "../../theme";
import { A, ButtonLikeA, GraphSubTitle, GraphTitle, } from "../common-styled-components";
import styled from "styled-components";
var VeilContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
var Veil = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 140px;\n  background-color: ", ";\n  border: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: ", ";\n  padding: ", ";\n"], ["\n  width: 100%;\n  height: 140px;\n  background-color: ", ";\n  border: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: ", ";\n  padding: ", ";\n"])), theme.colors.grey(5), theme.colors.grey(4), theme.spacing(2), theme.spacing(4));
var VeilText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n"], ["\n  color: ", ";\n  text-align: center;\n"])), theme.colors.grey(2));
export var PrivateContentVeil = function (_a) {
    var title = _a.title, subTitle = _a.subTitle, children = _a.children;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, loginWithRedirect = _b.loginWithRedirect;
    if (!isAuthenticated) {
        return (_jsxs(VeilContainer, { children: [_jsx(GraphTitle, { children: title }), subTitle !== undefined ? (_jsx(GraphSubTitle, { children: subTitle })) : null, _jsxs(Veil, { children: [_jsxs(VeilText, { children: [_jsx(A, __assign({ onClick: function (e) {
                                        e.preventDefault();
                                        loginWithRedirect();
                                    }, style: { cursor: "pointer" } }, { children: "Log in" })), " ", "or subscribe to see more details."] }), _jsx(ButtonLikeA, __assign({ href: "https://calendly.com/info-53a/get-ahti-calculator-pro", target: "_blank", color: theme.colors.blue }, { children: "Tell me more!" }))] })] }));
    }
    return children;
};
var templateObject_1, templateObject_2, templateObject_3;
