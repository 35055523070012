var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { routeNames } from "../../../../util/routes";
import { isValidShipInput, } from "../../compliance-pool-types";
import { Button, ComplianceIcon, SmallButton, StrippedLink, Table, TD, TH, TitleWithExtraContent, TR, } from "../../../common-styled-components";
import { complianceOptions, getCompliance, } from "../../../../util/compliance/compliance";
import { theme } from "../../../../theme";
import { formatNumber } from "../../../../util/amountUtils";
import { useState } from "react";
import { Modal } from "../../common/Modal";
import useEdit from "../../../../hooks/useEdit";
var shipTableColumns = function (showPool, isInAdminTools) {
    return [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "pool_name",
            label: "Pool",
        },
        {
            key: "intensity",
            label: "Intensity (gCO₂e/MJ)",
        },
        {
            key: "balance",
            label: "+/- (tCO₂e)",
        },
        {
            key: "tools",
            label: "",
        },
    ].filter(function (c) {
        return (c.key !== "pool_name" || showPool === true) &&
            (c.key !== "toold" || isInAdminTools === true);
    });
};
var CellContent = function (_a) {
    var ship = _a.ship, contentKey = _a.contentKey, compliance = _a.compliance, isInAdminTools = _a.isInAdminTools, removeShip = _a.removeShip, editShip = _a.editShip, userCompanyId = _a.userCompanyId;
    switch (contentKey) {
        case "name":
            if (isInAdminTools) {
                return (_jsx(StrippedLink, __assign({ to: "".concat(routeNames.admin, "/").concat(routeNames.shipAdmin, "/").concat(ship.id), style: { color: theme.colors.blue } }, { children: ship.name })));
            }
            else if (userCompanyId !== undefined &&
                ship.company_id === userCompanyId) {
                return (_jsx(StrippedLink, __assign({ to: "".concat(routeNames.complianceRoot, "/").concat(routeNames.ship, "/").concat(ship.id), style: { color: theme.colors.blue } }, { children: ship.name })));
            }
            return _jsx("div", { children: ship.name });
        case "engines":
            return _jsx("div", { children: ship.engines.map(function (e) { return e.type; }).join(", ") });
        case "intensity":
            if (ship.intensity) {
                return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [_jsx(ComplianceIcon, { src: complianceOptions[compliance].icon }), formatNumber(ship.intensity.adjusted_intensity_gCO2e_per_MJ, 2)] })));
            }
            else {
                return _jsx("div", {});
            }
        case "balance":
            if (ship.intensity) {
                return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [ship.intensity.adjusted_emission_balance_ton > 0 ? "+" : "", formatNumber(ship.intensity.adjusted_emission_balance_ton, 0)] })));
            }
            else {
                return _jsx("div", {});
            }
        case "tools":
            return removeShip && editShip ? (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removeShip(ship); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editShip(ship); } }, { children: "Edit" }))] })) : (_jsx("div", {}));
        default:
            return _jsx("div", { children: ship[contentKey] });
    }
};
export var ShipTable = function (_a) {
    var ships = _a.ships, complianceYear = _a.complianceYear, metadata = _a.metadata, isInAdminTools = _a.isInAdminTools, userCompanyId = _a.userCompanyId, showPool = _a.showPool, refresh = _a.refresh;
    var _b = useState({}), modal = _b[0], setModal = _b[1];
    var _c = useEdit("admin/ship.json", refresh), editStatus = _c[0], executeEdit = _c[1];
    var addShip = function () {
        setModal({
            type: "add",
            value: {
                engines: [
                    {
                        type: 3,
                        ship_engine_type: 1,
                    },
                ],
            },
        });
    };
    var editShip = function (ship) {
        setModal({
            type: "edit",
            value: ship,
        });
    };
    var removeShip = function (ship) {
        setModal({
            type: "remove",
            value: ship,
        });
    };
    var submit = function (type, value) {
        var _a, _b, _c, _d, _e, _f;
        setModal({});
        if (type === "add") {
            if (isValidShipInput(value)) {
                var data = {
                    name: value.name,
                    imo: value.imo,
                    id: null,
                    fleet_id: (_a = value.fleet_id) !== null && _a !== void 0 ? _a : null,
                    pool_id: (_b = value.pool_id) !== null && _b !== void 0 ? _b : null,
                    company_id: (_c = value.company_id) !== null && _c !== void 0 ? _c : null,
                    engines: value.engines,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Ship not valid");
            }
        }
        if (type === "edit") {
            if (isValidShipInput(value)) {
                var data = {
                    name: value.name,
                    imo: value.imo,
                    id: value.id,
                    fleet_id: (_d = value.fleet_id) !== null && _d !== void 0 ? _d : null,
                    pool_id: (_e = value.pool_id) !== null && _e !== void 0 ? _e : null,
                    company_id: (_f = value.company_id) !== null && _f !== void 0 ? _f : null,
                    engines: value.engines,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Ship not valid");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    return (metadata && (_jsxs("div", { children: [modal.type && (_jsx(Modal, { type: "ship", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit })), _jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: isInAdminTools && _jsx(Button, __assign({ onClick: addShip }, { children: "+ Add" })) }, { children: "Ships" })), _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: shipTableColumns(showPool, isInAdminTools).map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: ships.map(function (ship) {
                            var compliance = ship.intensity &&
                                getCompliance(metadata, complianceYear, ship.intensity.adjusted_intensity_gCO2e_per_MJ);
                            return (_jsx(TR, { children: shipTableColumns(showPool, isInAdminTools).map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, ship: ship, compliance: compliance, isInAdminTools: isInAdminTools, editShip: isInAdminTools ? editShip : undefined, removeShip: isInAdminTools ? removeShip : undefined, userCompanyId: userCompanyId }) }, c.key)); }) }, ship.id));
                        }) })] })] })));
};
