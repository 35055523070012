var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import { getAuthHeader } from "../../../util/auth";
import { Button, PageContent, Selector, SelectorLabel, } from "../../common-styled-components";
import useEdit from "../../../hooks/useEdit";
import { LoaderStatus } from "../../../util/apiUtils";
import { NumberInput } from "../../generic/NumberInput";
import { useState } from "react";
export default (function () {
    var user = useAuth0().user;
    var _a = useEdit("admin/test_data.json", __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), editStatus = _a[0], executeEdit = _a[1];
    var _b = useState(12), months = _b[0], setMonths = _b[1];
    return (_jsxs(PageContent, __assign({ style: { gap: 8 } }, { children: [_jsxs(Selector, __assign({ style: { maxWidth: 240 } }, { children: [_jsx(SelectorLabel, { children: "Months" }), _jsx(NumberInput, { value: months, onChange: function (newValue) { return setMonths(newValue); } })] })), _jsx(Button, __assign({ onClick: function () {
                    return executeEdit({ simple: true, "max-month": months }, "PUT");
                } }, { children: "Reset test data (simple)" })), _jsx(Button, __assign({ onClick: function () {
                    return executeEdit({ simple: false, "max-month": months }, "PUT");
                } }, { children: "Reset test data (full)" })), _jsx(Button, __assign({ onClick: function () {
                    return executeEdit({ simple: true, static: true, "max-month": months }, "PUT");
                } }, { children: "Reset test data (static, simple)" })), _jsx(Button, __assign({ onClick: function () {
                    return executeEdit({ simple: false, static: true, "max-month": months }, "PUT");
                } }, { children: "Reset test data (static, full)" })), _jsx(Button, __assign({ onClick: function () { return executeEdit(undefined, "DELETE"); } }, { children: "Delete test data" })), _jsxs("div", { children: [editStatus.status, editStatus.status === LoaderStatus.Loading
                        ? ". This might take a while!"
                        : ""] })] })));
});
