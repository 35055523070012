var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import useFetch from "../../hooks/useFetch";
import { Button, P, SectionTitle } from "../common-styled-components";
import FleetCalculatorResults from "./FleetCalculatorResults";
import ToolLayout from "../custom/ToolLayout";
import { useMetadata } from "../../hooks/useMetadata";
import { canCreateCalculation, getInitialComparisonFuelParam, getInitialFossilFuelParam, getInitialSecondComparisonFuelParam, getInitialSecondFossilFuelParam, } from "../../util/calculatorUtils";
import { useMergedCalculatorState } from "../../hooks/useMergedCalculatorState";
import { omit } from "lodash";
import { ToggleableContent } from "../generic/ToggleableContent";
import FleetCalculatorBasicSelectionPanel from "./FleetCalculatorBasicSelectionPanel";
import EnergySourceSelectionPanel from "../custom/EnergySourceSelectionPanel";
import { EditIcon } from "../custom/Icons";
import { useAuth0 } from "@auth0/auth0-react";
import { LoaderStatus } from "../../util/apiUtils";
export default (function () {
    var _a;
    var _b = useMergedCalculatorState(), calculatorParams = _b[0], setCalculatorParams = _b[1], removeFuel = _b[2];
    var metadata = useMetadata()[0];
    var isAuthenticated = useAuth0().isAuthenticated;
    // Init params from metadata
    useEffect(function () {
        if (metadata && calculatorParams === undefined) {
            setCalculatorParams({
                fleet_size: 30,
                emission_allowance_price: 90,
                fuels: [getInitialFossilFuelParam(metadata)],
                comparison_fuels: [getInitialComparisonFuelParam(metadata)],
            });
        }
    }, [metadata]);
    var _c = useFetch("calculator/cost-comparison", undefined, true), calculation = _c[0], _setUrl = _c[1], setBody = _c[2];
    var canCalculate = useDeepMemo(function () {
        return metadata && calculatorParams
            ? [canCreateCalculation(calculatorParams, metadata)]
            : [false];
    }, [calculatorParams, metadata])[0];
    useEffect(function () {
        if (canCalculate) {
            setBody(JSON.stringify(calculatorParams));
        }
    }, [calculatorParams, canCalculate]);
    // Data logging
    // console.info("params", calculatorParams);
    if (isAuthenticated) {
        console.info(calculation.status === LoaderStatus.Success
            ? "Results: " + JSON.stringify(calculation.data, undefined, 2)
            : "loading");
    }
    // console.info(
    //   metadata ? "Metadata: " + JSON.stringify(metadata, undefined, 2) : "loading"
    // );
    var languageData = useFetch("calculator/language/en", undefined)[0];
    var texts = languageData.status === LoaderStatus.Success
        ? languageData.data
        : undefined;
    var results = !canCalculate && texts ? (_jsx(P, __assign({ style: { height: "600px" } }, { children: "Select a comparison energy source with a lower intensity than the current energy source." }))) : calculatorParams &&
        calculation &&
        calculation.status === LoaderStatus.Success &&
        metadata &&
        texts ? (_jsx(FleetCalculatorResults, { params: calculatorParams, fuelTypes: metadata.fuels, calculation: calculation.data, metadata: metadata, texts: texts })) : (texts && _jsx(P, __assign({ style: { height: "600px" } }, { children: texts.loading_results })));
    return texts ? (_jsx(ToolLayout, { name: isAuthenticated ? "Fleet Calculator" : "Public Fleet Calculator", intro: "As regulation around shipping emissions tighten, Ahti calculator is a\n          tool for creating a concrete migration plan towards your new energy\n          source. Select your current energy source below, compare it to a host\n          of new options, and we will plan the fuel mix you need to the year\n          2050.", selections: metadata &&
            calculatorParams &&
            calculatorParams.comparison_fuels &&
            texts ? (_jsxs(_Fragment, { children: [_jsx(SectionTitle, { children: "Energy source options" }), calculatorParams.fuels.map(function (fuel, i) { return (_jsx(EnergySourceSelectionPanel, { title: "Fossil fuel".concat(calculatorParams.fuels.length > 1 ? " ".concat(i + 1) : ""), isComparison: false, useFOC: true, initialParams: fuel, metadata: metadata, setParams: setCalculatorParams, fuelIndex: i, texts: texts, removeFuel: calculatorParams.fuels.length > 1
                        ? function () { return removeFuel(false, i); }
                        : undefined }, fuel.fuel_type + i)); }), _jsxs(Button, __assign({ onClick: function (e) {
                        e.preventDefault();
                        setCalculatorParams({
                            fuels: __spreadArray(__spreadArray([], calculatorParams.fuels, true), [
                                getInitialSecondFossilFuelParam(metadata),
                            ], false),
                        });
                    } }, { children: [_jsx(EditIcon, { color: "white" }), _jsx("span", { children: "Add fossil source" })] })), _jsx(FleetCalculatorBasicSelectionPanel, { title: "Additional information", initialParams: omit(calculatorParams, "comparison_fuels", "fuels"), setParams: setCalculatorParams, texts: texts }), _jsx(EnergySourceSelectionPanel, { title: "Low-carbon energy source", isComparison: true, useFOC: false, initialParams: calculatorParams.comparison_fuels[0], metadata: metadata, setParams: setCalculatorParams, fuelIndex: 0, texts: texts }), _jsx(ToggleableContent, __assign({ buttonTitle: "Add low-carbon source", closeText: "Remove", defaultOpen: ((_a = calculatorParams.comparison_fuels) === null || _a === void 0 ? void 0 : _a.length) > 1, onClose: function () { return removeFuel(true, 1); }, onOpen: function () {
                        return setCalculatorParams({
                            comparison_fuels: [
                                calculatorParams.comparison_fuels[0],
                                getInitialSecondComparisonFuelParam(metadata),
                            ],
                        });
                    } }, { children: _jsx(EnergySourceSelectionPanel, { title: "Second low-carbon energy source", isComparison: true, useFOC: false, initialParams: calculatorParams.comparison_fuels[1], metadata: metadata, setParams: setCalculatorParams, fuelIndex: 1, texts: texts }) }))] })) : (texts && _jsx(P, { children: texts.loading_options })), results: results })) : (_jsx("div", {}));
});
