var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import App from "../App";
import MainPage from "../components/MainPage";
import Test from "../components/Test";
import CompliancePools from "../components/compliance-pools";
import AdminTools from "../components/compliance-pools/admin/AdminTools";
import Construction from "../components/compliance-pools/Construction";
import ShipAdmin from "../components/compliance-pools/admin/Ship";
import ShipsAdmin from "../components/compliance-pools/admin/Ships";
import VoyageAdmin from "../components/compliance-pools/admin/Voyage";
import FleetCalculator from "../components/fleet-calculator";
import SurplusCalculator from "../components/surplus-calculator";
import Voyage from "../components/voyage";
import PoolAdmin from "../components/compliance-pools/admin/Pool";
import PoolsAdmin from "../components/compliance-pools/admin/Pools";
import TransactionsAdmin from "../components/compliance-pools/admin/Transactions";
import CompaniesAdmin from "../components/compliance-pools/admin/Companies";
import FleetsAdmin from "../components/compliance-pools/admin/Fleets";
import { ProtectedRoute } from "../components/ProtectedRoute";
import TestDataTools from "../components/compliance-pools/admin/TestDataTools";
export var routeNames = {
    main: "/",
    voyage: "/voyage-calculator",
    fleetCalculator: "/fleet-calculator",
    surplusCalculator: "/surplus-calculator",
    compliancePools: "/compliance",
    ships: "compliance/ships",
    ship: "compliance/ship",
    companiesAdmin: "companies",
    fleetsAdmin: "fleets",
    transactionsAdmin: "transactions",
    login: "/login",
    test: "/test",
    admin: "/compliance/admin",
    testDataTools: "test-data-tools",
    shipsAdmin: "ships",
    shipAdmin: "ship",
    voyageAdmin: "voyage",
    poolsAdmin: "pools",
    poolAdmin: "pool",
};
export var routes = [
    {
        path: routeNames.main,
        element: _jsx(App, {}),
        children: [
            {
                path: routeNames.voyage,
                element: (_jsx(ProtectedRoute, { children: _jsx(Voyage, {}) })),
            },
            {
                path: routeNames.test,
                element: (_jsx(ProtectedRoute, __assign({ needsAdmin: true }, { children: _jsx(Test, {}) }))),
            },
            {
                path: routeNames.fleetCalculator,
                element: _jsx(FleetCalculator, {}),
            },
            {
                path: routeNames.surplusCalculator,
                element: _jsx(SurplusCalculator, {}),
            },
            {
                path: routeNames.compliancePools,
                element: (_jsx(ProtectedRoute, { children: _jsx(CompliancePools, {}) })),
            },
            {
                path: routeNames.ships,
                element: (_jsx(ProtectedRoute, { children: _jsx(Construction, {}) })),
            },
            {
                path: "".concat(routeNames.ship, "/:shipId"),
                element: (_jsx(ProtectedRoute, { children: _jsx(Construction, {}) })),
            },
            {
                path: routeNames.admin,
                element: (_jsx(ProtectedRoute, __assign({ needsAdmin: true }, { children: _jsx(AdminTools, {}) }))),
                children: [
                    {
                        path: routeNames.shipsAdmin,
                        element: _jsx(ShipsAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.shipAdmin, "/:shipId"),
                        element: _jsx(ShipAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.voyageAdmin, "/:voyageId"),
                        element: _jsx(VoyageAdmin, {}),
                    },
                    {
                        path: routeNames.poolsAdmin,
                        element: _jsx(PoolsAdmin, {}),
                    },
                    {
                        path: "".concat(routeNames.poolAdmin, "/:poolId"),
                        element: _jsx(PoolAdmin, {}),
                    },
                    {
                        path: routeNames.transactionsAdmin,
                        element: _jsx(TransactionsAdmin, {}),
                    },
                    {
                        path: routeNames.companiesAdmin,
                        element: _jsx(CompaniesAdmin, {}),
                    },
                    {
                        path: routeNames.fleetsAdmin,
                        element: _jsx(FleetsAdmin, {}),
                    },
                    {
                        path: routeNames.testDataTools,
                        element: _jsx(TestDataTools, {}),
                    },
                ],
            },
            {
                path: routeNames.main,
                element: _jsx(MainPage, {}),
            },
        ],
    },
];
