var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "../../../hooks/useFetch";
import { getAuthHeader } from "../../../util/auth";
import { Button, PageContent, SmallButton, TD, TH, TR, Table, TitleWithExtraContent, } from "../../common-styled-components";
import { useState } from "react";
import { Modal } from "./Modal";
import { LoaderStatus } from "../../../util/apiUtils";
import useEdit from "../../../hooks/useEdit";
var tableColumns = [
    {
        key: "name",
        label: "Name",
    },
    // {
    //   key: "ownedShipImoNumbers",
    //   label: "Owned ships",
    // },
    // {
    //   key: "operatedShipImoNumbers",
    //   label: "Operated ships",
    // },
    {
        key: "tools",
        label: "Tools",
    },
];
var CellContent = function (_a) {
    var company = _a.company, contentKey = _a.contentKey, editCompany = _a.editCompany, removeCompany = _a.removeCompany;
    switch (contentKey) {
        case "tools":
            return (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removeCompany(company); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editCompany(company); } }, { children: "Edit" }))] }));
        default:
            return _jsx("div", { children: company[contentKey] });
    }
};
export default (function () {
    var user = useAuth0().user;
    var _a = useFetch("admin/companies.json", undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), companies = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    var _b = useEdit("admin/company.json", __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }), refresh), editStatus = _b[0], executeEdit = _b[1];
    var _c = useState({}), modal = _c[0], setModal = _c[1];
    var addCompany = function () {
        setModal({
            type: "add",
            value: undefined,
        });
    };
    var editCompany = function (company) {
        setModal({
            type: "edit",
            value: company,
        });
    };
    var removeCompany = function (company) {
        setModal({
            type: "remove",
            value: company,
        });
    };
    var submit = function (type, value) {
        var _a;
        setModal({});
        if (type === "add") {
            if (value.name && value.name !== "") {
                var data = {
                    name: value.name,
                    username: value === null || value === void 0 ? void 0 : value.username,
                    id: null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "edit") {
            if (value.name && value.name !== "") {
                var data = {
                    name: value.name,
                    username: value === null || value === void 0 ? void 0 : value.username,
                    id: (_a = value.id) !== null && _a !== void 0 ? _a : null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
            else {
                alert("Empty name");
            }
        }
    };
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(Button, __assign({ onClick: addCompany }, { children: "+ Add" })) }, { children: "Companies" })), modal.type && (_jsx(Modal, { type: "company", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit })), _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [tableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }), " "] }) }), _jsx("tbody", { children: companies.status === LoaderStatus.Success &&
                            companies.data.map(function (company) { return (_jsx(TR, { children: tableColumns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, company: company, removeCompany: removeCompany, editCompany: editCompany }) }, c.key)); }) }, company.id)); }) })] })] })));
});
