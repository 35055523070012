var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageContent, SectionTitle } from "../../common-styled-components";
import useFetch from "../../../hooks/useFetch";
import { LoaderStatus } from "../../../util/apiUtils";
import { ShipTable } from "../common/tables/ShipTable";
import { useMetadata } from "../../../hooks/useMetadata";
import { defaultYear } from "../../../util/constants";
export default (function () {
    var _a = useFetch("ship/ships.json", undefined), ships = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    var user = useFetch("user/data.json")[0];
    var metadata = useMetadata()[0];
    if (!metadata ||
        ships.status !== LoaderStatus.Success ||
        user.status !== LoaderStatus.Success) {
        return _jsx(PageContent, { children: "Loading\u2026" });
    }
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(SectionTitle, { children: "Ships" }), _jsx(ShipTable, { metadata: metadata, ships: ships.data, complianceYear: defaultYear, showPool: true, refresh: refresh, isInAdminTools: false, userCompanyId: user.data.company.id })] })));
});
