var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { Bold, PageContent, PTight, Row, SectionTitle, SeparatedRow, SmallSubtitle, WideTextContent, } from "../../common-styled-components";
import useFetch from "../../../hooks/useFetch";
import { LoaderStatus } from "../../../util/apiUtils";
import ComplianceKPI from "../common/ComplianceKPI";
import { getCompliance } from "../../../util/compliance/compliance";
import { useMetadata } from "../../../hooks/useMetadata";
import { theme } from "../../../theme";
import { useEffect } from "react";
import { scaleEmissions } from "../util";
import { formatNumber } from "../../../util/amountUtils";
import { UsageTable } from "../common/tables/UsageTable";
import { defaultYear } from "../../../util/constants";
export default (function () {
    var voyageId = useParams().voyageId;
    var metadata = useMetadata()[0];
    var voyages = useFetch("admin/voyages.json", JSON.stringify({
        voyage_id: Number(voyageId),
    }))[0];
    var usages = useFetch("admin/usages.json", JSON.stringify({
        voyage_id: Number(voyageId),
    }))[0];
    console.log("Usages:", usages.status === LoaderStatus.Success ? usages.data : "loading");
    var _a = useFetch("admin/ships.json", undefined, true), ship = _a[0], _setShipUrl = _a[1], setShipPostBody = _a[2];
    useEffect(function () {
        if (voyages.status === LoaderStatus.Success &&
            ship.status !== LoaderStatus.Success &&
            voyages.data[0]) {
            setShipPostBody(JSON.stringify({ voyage_id: voyages.data[0].ship_id }));
        }
    }, [voyages.status]);
    // const [usageAnalysis] = useFetch<ShipFuelUsages>(
    //   "admin/ship_fuel_usage.json",
    //   JSON.stringify({ ship_id: Number(shipId), year: defaultYear }),
    // );
    if (
    // ship.status !== LoaderStatus.Success ||
    voyages.status !== LoaderStatus.Success ||
        usages.status !== LoaderStatus.Success ||
        ship.status !== LoaderStatus.Success ||
        // usageAnalysis.status !== LoaderStatus.Success ||
        !metadata) {
        return _jsx(PageContent, { children: "Loading\u2026" });
    }
    // const shipData = ship.data[0];
    var voyageData = voyages.data[0];
    var usageData = usages.data;
    var shipData = ship.data[0];
    if (!voyageData) {
        return _jsx(PageContent, { children: "Voyage not found!" });
    }
    // console.log("Ship data:", shipData);
    console.log("Voyage:", voyageData);
    // console.log(
    //   "Usage analysis:",
    //   usageAnalysis.status === LoaderStatus.Success
    //     ? usageAnalysis.data
    //     : "loading"
    // );
    // const analysis = usageAnalysis.data.ship.analysis;
    var minCellWidth = 420;
    var title = voyageData.type_id === 1
        ? "".concat(shipData.name, ": Arrival to ").concat(voyageData.destination_name)
        : "".concat(shipData.name, ": Port stay at ").concat(voyageData.origin_name);
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(SectionTitle, { children: title }), _jsxs(SeparatedRow, { children: [_jsxs("div", __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsxs(PTight, { children: [_jsx(Bold, { children: "Start date: " }), new Date(voyageData.start_date).toLocaleString("en-US"), ";"] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "End date: " }), new Date(voyageData.end_date).toLocaleString("en-US"), ";"] }), _jsxs(PTight, { children: [_jsx(Bold, { children: "Distance: " }), formatNumber(voyageData.distance), " NM"] })] })), _jsxs(Row, { children: [_jsx(ComplianceKPI, { compliance: getCompliance(metadata, defaultYear, voyageData.intensity.intensity_gCO2e_per_MJ), title: "Carbon intensity", unit: "gCO\u2082e/MJ", value: voyageData.intensity.intensity_gCO2e_per_MJ }), _jsx(ComplianceKPI, { compliance: getCompliance(metadata, defaultYear, voyageData.intensity.intensity_gCO2e_per_MJ), title: "Carbon unit balance", unit: "tCO\u2082e", value: scaleEmissions(voyageData.intensity.emission_balance_ton) })] })] }), _jsxs(WideTextContent, __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: "Usages" })), _jsx(UsageTable, { usages: usageData, metadata: metadata, complianceYear: defaultYear, isInAdminTools: true })] }))] })));
});
