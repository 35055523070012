import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Selector, SelectorLabel } from "../../../common-styled-components";
import { TextInput } from "../../../generic/TextInput";
export var CompanyEditor = function (_a) {
    var _b, _c;
    var state = _a.state, updateValue = _a.updateValue, modalType = _a.modalType;
    if (modalType === "remove") {
        return _jsxs("div", { children: ["Remove ", state.name, "?"] });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Company name" }), _jsx(TextInput, { value: (_b = state.name) !== null && _b !== void 0 ? _b : "", onChange: function (newValue) { return updateValue("name", newValue); } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "User email" }), _jsx(TextInput, { value: (_c = state.username) !== null && _c !== void 0 ? _c : "", onChange: function (newValue) { return updateValue("username", newValue); } })] })] }));
};
