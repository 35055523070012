var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { CenteredRow, Grid, PageContent, SectionTitle, SmallSubtitle, WideTextContent, } from "../../common-styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "../../../hooks/useFetch";
import { getAuthHeader } from "../../../util/auth";
import { LoaderStatus } from "../../../util/apiUtils";
import { useMetadata } from "../../../hooks/useMetadata";
import ComplianceKPI from "./ComplianceKPI";
import { getCompliance } from "../../../util/compliance/compliance";
import LineChart from "../LineChart";
import { theme } from "../../../theme";
import { getFuelColor, getTransactionPOI, scaleEmissions } from "../util";
import { ShipTable } from "./ShipTable";
import { VoyageTable } from "./VoyageTable";
import { TransactionTable } from "./TransactionTable";
export default (function () {
    var poolId = useParams().poolId;
    var user = useAuth0().user;
    var _a = useFetch("admin/pools.json", JSON.stringify({
        pool_id: Number(poolId),
        year: 2024,
    }), __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), pools = _a[0], _setUrl = _a[1], _setBody = _a[2], _refresh = _a[3];
    var usageAnalysis = useFetch("admin/pool_fuel_usage.json", JSON.stringify({ pool_id: Number(poolId), year: 2024 }), __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    var voyages = useFetch("admin/voyages.json", JSON.stringify({ pool_id: Number(poolId), year: 2024 }), __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    var _b = useFetch("admin/transactions.json", JSON.stringify({
        pool_id: Number(poolId),
        year: 2024,
    }), __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), transactions = _b[0], _setTransUrl = _b[1], _setTransPostBody = _b[2], refreshTransactions = _b[3];
    var metadata = useMetadata()[0];
    // const { intensities, fuelSpend } = useDeepMemo(() => {
    //   if (metadata) {
    //     const { reports } = getSimulatedReportsAndFleet(0, metadata);
    //     return analyzeReports(reports, metadata, 2025, 2025);
    //   }
    //   return { intensities: undefined, fuelSpend: undefined };
    // }, [metadata]);
    var poolData = pools.status === LoaderStatus.Success ? pools.data[0] : null;
    var analysis = usageAnalysis.status === LoaderStatus.Success
        ? usageAnalysis.data.pool.analysis
        : null;
    var voyageData = voyages.status === LoaderStatus.Success ? voyages.data : null;
    var transactionData = transactions.status === LoaderStatus.Success ? transactions.data : null;
    var poiData = transactionData === null || transactionData === void 0 ? void 0 : transactionData.map(function (t) {
        return getTransactionPOI(t, "neutral");
    });
    console.log("Pools:", pools !== null && pools !== void 0 ? pools : "loading");
    console.log("Usage analysis:", usageAnalysis.status === LoaderStatus.Success
        ? usageAnalysis.data
        : "loading");
    var minCellWidth = 420;
    // if (!poolData || pools.status === LoaderStatus.Error) {
    //   return <PageContent>Pool {poolId} not found!</PageContent>;
    // }
    var carbonLimitYear = 2025;
    if (poolData && analysis && metadata) {
        return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(SectionTitle, { children: poolData.name }), _jsxs(CenteredRow, __assign({ gap: 7 }, { children: [_jsx(ComplianceKPI, { compliance: getCompliance(metadata, carbonLimitYear, analysis.intensities.total.intensity_gCO2e_per_MJ), title: "Carbon intensity", unit: "gCO\u2082e/MJ", value: analysis.intensities.total.intensity_gCO2e_per_MJ }), _jsx(ComplianceKPI, { compliance: getCompliance(metadata, carbonLimitYear, analysis.intensities.total.intensity_gCO2e_per_MJ), title: "Carbon unit balance", unit: "tCO\u2082e", value: scaleEmissions(analysis.intensities.total.emission_balance_ton) })] })), _jsxs(Grid, __assign({ minCellWidth: minCellWidth }, { children: [_jsx("div", { children: _jsx(LineChart, { title: "Pool intensity", unit: "gCO\u2082e/MJ", poiData: poiData, data: [
                                    {
                                        name: "Intensity",
                                        color: theme.colors.blackText,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.intensity_gCO2e_per_MJ,
                                        }); }),
                                        // prediction:
                                        //   predictions?.intensities?.carbonIntensity !== undefined
                                        //     ? {
                                        //         date: predictions.date,
                                        //         value: predictions.intensities.carbonIntensity!,
                                        //       }
                                        //     : undefined,
                                    },
                                ], limits: [
                                    {
                                        label: "".concat(carbonLimitYear, " limit"),
                                        value: metadata.intensity[carbonLimitYear],
                                        color: theme.colors.red,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Pool carbon units", unit: "tCO\u2082e", poiData: poiData, data: [
                                    {
                                        name: "Carbon unit balance",
                                        color: theme.colors.blackText,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.emission_balance_ton,
                                        }); }),
                                        // prediction:
                                        //   showPredictions &&
                                        //   predictions?.intensities?.emissionsBalanceTons !== undefined
                                        //     ? {
                                        //         date: predictions.date,
                                        //         value: predictions.intensities.emissionsBalanceTons!,
                                        //       }
                                        //     : undefined,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative emissions", unit: "tCO\u2082e", poiData: poiData, data: [
                                    {
                                        name: "Emissions",
                                        color: theme.colors.blackText,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.co2_emission_ton,
                                        }); }),
                                        // prediction:
                                        //   showPredictions &&
                                        //   predictions?.intensities?.co2eEmissionTons !== undefined
                                        //     ? {
                                        //         date: predictions.date,
                                        //         value: predictions.intensities.co2eEmissionTons!,
                                        //       }
                                        //     : undefined,
                                    },
                                    {
                                        name: "Emission limit",
                                        color: theme.colors.red,
                                        dashed: true,
                                        data: analysis.intensities.cumulative.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.intensity.emission_limit_ton,
                                        }); }),
                                        // prediction:
                                        //   showPredictions &&
                                        //   predictions?.intensities?.emissionsLimitTons !== undefined
                                        //     ? {
                                        //         date: predictions.date,
                                        //         value: predictions.intensities.emissionsLimitTons!,
                                        //       }
                                        //     : undefined,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative fuel use", unit: "t", data: Object.keys(analysis.fuel_spent.cumulative_fuel_timeline).map(function (fuelKey, i) {
                                    var _a, _b;
                                    var fuelData = analysis.fuel_spent.cumulative_fuel_timeline[fuelKey];
                                    return {
                                        name: (_b = (_a = metadata.fuels[fuelData.fuel]) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : "Name not found",
                                        color: getFuelColor(fuelData.fuel),
                                        data: fuelData.timeline.map(function (d) { return ({
                                            date: new Date(d.date),
                                            value: d.amount,
                                        }); }),
                                        // prediction:
                                        //   showPredictions &&
                                        //   predictions?.fuels?.[timelineData.fuel] !== undefined
                                        //     ? {
                                        //         date: predictions.date,
                                        //         value: predictions?.fuels?.[timelineData.fuel],
                                        //       }
                                        //     : undefined,
                                    };
                                }) }) })] })), _jsxs(WideTextContent, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: "Ships" })), _jsx(ShipTable, { ships: poolData.ships, metadata: metadata, complianceYear: carbonLimitYear, isInAdminTools: true })] }), transactionData && transactionData.length > 0 && (_jsxs(WideTextContent, __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: "Compliance transactions" })), _jsx(TransactionTable, { transactions: transactionData, complianceYear: carbonLimitYear, poolId: poolData.id, refresh: refreshTransactions, isInAdminTools: true })] }))), voyageData && voyageData.length > 0 && (_jsxs(WideTextContent, __assign({ style: { marginTop: theme.spacing(4) } }, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(2) } }, { children: "Voyages" })), _jsx(VoyageTable, { voyages: voyageData, metadata: metadata, complianceYear: carbonLimitYear, isInAdminTools: true })] })))] })));
    }
    return _jsx(PageContent, { children: "Loading pool\u2026" });
});
