var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { breakpoints, theme } from "../../theme";
import { P } from "../common-styled-components";
import shipLow from "../../../images/ship-intensity-low.png";
var FooterContainer = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-top: 1px solid ", ";\n  padding: ", " ", ";\n  margin-top: ", ";\n\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    padding: ", " 0;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-top: 1px solid ", ";\n  padding: ", " ", ";\n  margin-top: ", ";\n\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    padding: ", " 0;\n  }\n"])), theme.colors.grey(4), theme.spacing(3), theme.spacing(4), theme.spacing(6), breakpoints.mobilePlus, theme.spacing(3));
var FooterThings = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: ", ";\n"])), function (p) { return (p.align === "left" ? "flex-start" : "flex-end"); });
var ShipImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 48px;\n  margin-right: ", ";\n"], ["\n  height: 48px;\n  margin-right: ", ";\n"])), theme.spacing(2));
export var Footer = function (_a) {
    var children = _a.children;
    return (_jsxs(FooterContainer, { children: [_jsx(FooterThings, __assign({ align: "left" }, { children: _jsx(ShipImage, { src: shipLow }) })), _jsxs(FooterThings, __assign({ align: "right" }, { children: [children, _jsx(P, { children: "\u00A9 Ahti Climate" })] }))] }));
};
var templateObject_1, templateObject_2, templateObject_3;
